export const stockArray = [{
  id:1,
  name:'日经指数',
  code: 'N225',
  openTime: "08:00:00",
  closeTime: "14:00:00",
  sliderName: '日經225指數',
  region: 'asia',
  timeZone: 'Asia/Tokyo',
},{
  id:2,
  name:'韩国综合',
  code: 'KS11',
  openTime: "08:00:00",
  closeTime: "14:00:00",
  sliderName: '韩国综合',
  region: 'asia',
  timeZone: 'Asia/Seoul',
},{
  id:3,
  name:'香港恒生',
  code: 'HSI',
  openTime: "09:30:00",
  closeTime: "16:00:00",
  sliderName: '香港恒生',
  region: 'asia',
  timeZone: 'Asia/Hong_Kong',
},{
  id:4,
  name:'上海综合',
  code: '000001.SS',
  openTime: "09:30:00",
  closeTime: "15:00:00",
  sliderName: '上海综合',
  region: 'asia',
  timeZone: 'Asia/Shanghai',
},{
  id:5,
  name:'富时新加坡海峡时报指数',
  code: 'STI',
},{
  id:6,
  name:'越南胡志明股票指数',
  code: 'VNI',
},{
  id:7,
  name:'英国富时100指数',
  code: 'FTSE',
  sliderName: '英国富时100',
  region: 'euro',
  timeZone: 'Europe/London',
},{
  id:8,
  name:'德国DAX 30种股价指数',
  code: 'GDAXI',
  sliderName: '德国DAX30',
  region: 'euro',
  timeZone: 'Europe/Berlin',
}, {
  id:9,
  name:'法CAC40指数',
  code: 'CAC',
  sliderName: '法国CAC40',
  region: 'euro',
  timeZone: 'Europe/Paris',
},{
  id:10,
  name:'瑞士股票指数',
  code: 'SMI',
},{
  id:11,
  name:' 西班牙IBEX指数',
  code: 'IBEX',
}, {
  id:12,
  name:'荷兰AEX综合指数',
  code: 'AEX',
  sliderName: '荷兰AEX',
  region: 'euro',
  timeZone: 'Europe/Amsterdam',
}, {
  id:13,
  name:'道琼斯',
  code: 'DJI'
}, {
  id:14,
  name:'标准普尔500',
  code: 'GSPC',
  sliderName: 'S&P500',
},
  {
    id:15,
    name:'纳斯达克',
    code: 'IXIC',
  },
  {
    id:16,
    name:' 加拿大S&P/TSX综合指数',
    code: 'TSX',
  },
  {
    id:17,
    name:'墨西哥BOLSA指数',
    code: 'MXX',
  },
  {
    id:18,
    name:'巴西BOVESPA股票指数',
    code: 'BVSP',
  },{
    id:19,
    name:'澳大利亚标准普尔200指数',
    code: 'ASX200',
    sliderName: '澳大利亚标普200',
  },
  {
    id:20,
    name:'新西兰NZSE 50指数',
    code: 'NZX50',
    sliderName: '新西兰NZSE 50',
  },
  {
    id:21,
    name:'澳交所普通股指数',
    code: 'XAO',
    sliderName: '澳交所普通股指数',
  }
]

export default function(stockCode, byStockId = false) {
  if (byStockId === true){
    return stockArray.find(({id}) => id ===stockCode)
  }
  return stockArray.find(({code}) => code ===stockCode)
}

