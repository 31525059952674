<script setup>
import {onUnmounted, onMounted, ref, watch} from 'vue';
import useColorClass from '@/hook/useColorClass';
import { useRoute } from 'vue-router';
import axios from 'axios';
import useConfig from '@/hook/useConfig';
import useStockProperty from '@/hook/useStockProperty';
import {format} from 'date-fns';

const route = useRoute();
const { comparison } = route.params;
const stockA = ref(useStockProperty(comparison.split('-vs-')[0]));
const stockB = ref(useStockProperty(comparison.split('-vs-')[1]));
const stockAIndexData = ref({})
const stockBIndexData = ref({})
const totalItems = ref(0);
const currentPage = ref(1);
const historyData = ref([])
const elapseTimerA = ref({
  day:0,
  hours:0,
  minutes:0,
  seconds:0,
})
const elapseTimerB = ref({
  day:0,
  hours:0,
  minutes:0,
  seconds:0,
})
const showTab = ref('time');
let pollingId = 0
let elapseId = 0;
let increment = 0;

function polling(){
  // setTimeout(()=>{
 axios.get(`/api/BattleInfo/GetBattleHistory?teamAStockId=${stockA.value.id}&teamBStockId=${stockB.value.id}&pageIndex=${currentPage.value}&pageSize=${useConfig().pageSize}`).then(response=>{
    stockAIndexData.value = response.data.data.mainStock
    stockBIndexData.value = response.data.data.customerStock
    historyData.value = response.data.data.history
    totalItems.value = response.data.data.recordCount
    if (increment === 0){
      elapseCloseTime();
    }
    increment++;
  })
 // },30000000)
  pollingId = setTimeout (polling, useConfig().interval)
}

const onClickHandler = (page) => {
  currentPage.value = page

  axios.get(`/api/BattleInfo/GetBattleHistory?teamAStockId=${stockA.value.id}&teamBStockId=${stockB.value.id}&pageIndex=${currentPage.value}&pageSize=${useConfig().pageSize}`).then(response=>{
    stockAIndexData.value = response.data.data.mainStock
    stockBIndexData.value = response.data.data.customerStock
    historyData.value = response.data.data.history
    totalItems.value = response.data.data.recordCount
  })
};


watch(route, ({params}) => {
  stockA.value = useStockProperty(params.comparison.split('-vs-')[0]);
  stockB.value = useStockProperty(params.comparison.split('-vs-')[1]);
  initialState()
})

function initialState(){
  increment = 0;
  stockAIndexData.value = {}
  stockBIndexData.value = {}
  historyData.value = []
  elapseTimerA.value = {
    day:0,
    hours:0,
    minutes:0,
    seconds:0,
  }
  elapseTimerB.value = {
    day:0,
    hours:0,
    minutes:0,
    seconds:0,
  }
  totalItems.value = 0;
  currentPage.value = 1;
  showTab.value = 'time'
  clearTimeout (pollingId);
  clearTimeout (elapseId)
  polling();
}

function elapseCloseTime(){

  if (new Date(stockAIndexData.value.openDateTime.split('T')[0].replace(/-/g, "/")).getDate() === new Date().getDate()){
    let now = new Date();
    let closingDateTime = new Date( format(now, 'yyyy/MM/dd') + ' ' + stockAIndexData.value.closeTime)
    let totalSeconds = (closingDateTime.getTime() - now.getTime())/1000
    if(totalSeconds > 0){
      elapseTimerA.value = {
        day: Math.floor(totalSeconds/86400),
        hours: Math.floor(totalSeconds/3600),
        minutes: Math.floor((totalSeconds%3600)/60),
        seconds: Math.floor((totalSeconds%3600)%60)
      }
    }
  }

  if (new Date(stockBIndexData.value.openDateTime.split('T')[0].replace(/-/g, "/")).getDate() === new Date().getDate()){
    let now = new Date();
    let closingDateTime = new Date( format(now, 'yyyy/MM/dd') + ' ' + stockBIndexData.value.closeTime)
    let totalSeconds = (closingDateTime.getTime() - now.getTime())/1000
    if(totalSeconds > 0){
      elapseTimerB.value = {
        day: Math.floor(totalSeconds/86400),
        hours: Math.floor(totalSeconds/3600),
        minutes: Math.floor((totalSeconds%3600)/60),
        seconds: Math.floor((totalSeconds%3600)%60)
      }
    }

  }
  elapseId = setTimeout(elapseCloseTime, 1000)
}

const wrapLastDigit = (digit)=> {
  const string = digit.toString()
  if (/\./g.test(string)){
    const formattedString = digit.toFixed(2).toString()
    return [...formattedString].reverse().map((element, index) => (index === 3) ? `<strong class='text-blue'>${element}</strong>`: element).reverse().join('')
  }else{
    return [...string].reverse().map((element, index) => (index === 0) ? `<strong class='text-blue'>${element}</strong>`: element).reverse().join('')
  }
}

onMounted (()=>{
  polling()
  document.title = "全球指数-全方位指数统计网站 |  历史数据";
})
onUnmounted (() => {clearTimeout (pollingId);clearTimeout (elapseId)})
</script>
<template>
<div class='w-full body-wrapper'>
  <div class='overview-row'>
    <div class='overview'>
      <div class='text-center' v-if='stockAIndexData.openDateTime'>
        <h1>{{stockAIndexData.stockName}}</h1>
        <div class='datetime'>{{stockAIndexData.openDateTime?.replace('T', ' ')}}</div>
        <div class='price' :class='"text-"+useColorClass(stockAIndexData.increasePrice)'>{{stockAIndexData.lastPrice}}</div>
      </div>
      <div v-else class='row flex items-center placeholder'>
        <h1>{{stockA.name}}</h1>
        <div class='box py-4 w-52'></div>
        <div class='price py-6 w-24'></div>
      </div>
      <div class='flex justify-center items-center' v-if='stockAIndexData.lastPrice'>
        <template v-if='stockAIndexData.increasePrice !== 0'>
          <div :class='(stockAIndexData.increasePrice > 0 ? "rising" : "dropping")+"-arrow"'><span></span></div>
        </template>
        <template v-else>
          <div class='even-symbol'><span></span></div>
        </template>
        <div class='flex difference text-left items-center ml-1 gap-6' :class='"text-"+useColorClass(stockAIndexData.increasePrice)'>
          <div>{{stockAIndexData.increasePrice > 0 ? '+' : ''}}{{stockAIndexData.increasePrice}}</div>
          <div>{{stockAIndexData.increasePrice > 0 ? '+' : ''}}{{stockAIndexData.increaseRate.toFixed(2)}}%</div>
        </div>
      </div>
      <div class='flex justify-center placeholder' v-else>
        <div><span>&nbsp;</span></div>
        <div class='flex difference text-left items-center ml-1 gap-6 '>
          <div class='box w-24 py-4'></div>
          <div class='box w-16 py-4'></div>
        </div>
      </div>
      <div class='countdown-wrapper'>
        距离收盘
        <div class='countdown-timer' v-if='stockAIndexData.lastPrice'>
          <div>{{elapseTimerA.day}}<div class='unit'>天</div></div>
          <div>{{elapseTimerA.hours}}<div class='unit'>时</div></div>
          <div>{{elapseTimerA.minutes}}<div class='unit'>分</div></div>
          <div>{{elapseTimerA.seconds}}<div class='unit'>秒</div></div></div>

        <div class='countdown-timer' v-else>
          <div>&nbsp;<div class='unit'>天</div></div>
          <div>&nbsp;<div class='unit'>时</div></div>
          <div>&nbsp;<div class='unit'>分</div></div>
          <div>&nbsp;<div class='unit'>秒</div></div>
        </div>
      </div>
      <table class='overview-table'>
        <thead>
        <tr>
          <th>今开</th>
          <th>昨收</th>
        </tr>
        </thead>
        <tbody>
        <tr>
          <td :class='"text-" + useColorClass(stockAIndexData.increasePrice)'>
            <span v-if='stockAIndexData.openPrice'>{{stockAIndexData.openPrice}}</span>
            <span v-else>&nbsp;</span>
          </td>
          <td>
            <span v-if='stockAIndexData.yesterdayPrice'>{{stockAIndexData.yesterdayPrice}}</span>
            <span v-else>&nbsp;</span>
          </td>
        </tr>
        </tbody>
      </table>
      <table class='overview-table'>
        <tr>
          <th>最高</th>
          <td>最低</td>
          <td>振幅</td>
        </tr>
        <tr>
          <td :class='"text-" + useColorClass(stockAIndexData.increasePrice)'>
            <span v-if='stockAIndexData.maxTopPrice'>{{stockAIndexData.maxTopPrice}}</span>
            <span v-else>&nbsp;</span>
          </td>
          <td>
            {{stockAIndexData.minTopPrice}}
          </td>
          <td>
            <span v-if='stockAIndexData.amplitudeRate || stockAIndexData.amplitudeRate===0'>{{stockAIndexData.amplitudeRate.toFixed(2)}}%</span>
            <span v-else>&nbsp;</span>
          </td>
        </tr>
      </table>
    </div>


    <div class='overview'>
      <div class='text-center' v-if='stockBIndexData.openDateTime'>
        <h1>{{stockBIndexData.stockName}}</h1>
        <div class='datetime'>{{stockBIndexData.openDateTime?.replace('T', ' ')}}</div>
        <div class='price'  :class='"text-"+useColorClass(stockBIndexData.increasePrice)'>{{stockBIndexData.lastPrice}}</div>
      </div>
      <div v-else class='row flex items-center placeholder'>
        <h1>{{stockB.name}}</h1>
        <div class='box py-4 w-52'></div>
        <div class='price py-6 w-24'></div>
      </div>
      <div class='flex justify-center items-center' v-if='stockBIndexData.lastPrice'>
        <template v-if='stockBIndexData.increasePrice !== 0'>
          <div :class='(stockBIndexData.increasePrice >= 0 ? "rising" : "dropping")+"-arrow"'><span></span></div>
        </template>
        <template v-else>
          <div class='even-symbol'><span></span></div>
        </template>
        <div class='flex difference text-left items-center ml-1 gap-6' :class='"text-"+useColorClass(stockBIndexData.increasePrice)'>
          <div>{{stockBIndexData.increasePrice > 0 ? '+' : ''}}{{stockBIndexData.increasePrice}}</div>
          <div>{{stockBIndexData.increasePrice > 0 ? '+' : ''}}{{stockBIndexData.increaseRate.toFixed(2)}}%</div>
        </div>
      </div>
      <div class='flex justify-center placeholder' v-else>
        <div><span>&nbsp;</span></div>
        <div class='flex difference text-left items-center ml-1 gap-6 '>
          <div class='box w-24 py-4'></div>
          <div class='box w-16 py-4'></div>
        </div>
      </div>
      <div class='countdown-wrapper'>
        距离收盘
        <div class='countdown-timer' v-if='stockBIndexData.lastPrice'>
          <div>{{elapseTimerB.day}}<div class='unit'>天</div></div>
          <div>{{elapseTimerB.hours}}<div class='unit'>时</div></div>
          <div>{{elapseTimerB.minutes}}<div class='unit'>分</div></div>
          <div>{{elapseTimerB.seconds}}<div class='unit'>秒</div></div></div>

        <div class='countdown-timer' v-else>
          <div>&nbsp;<div class='unit'>天</div></div>
          <div>&nbsp;<div class='unit'>时</div></div>
          <div>&nbsp;<div class='unit'>分</div></div>
          <div>&nbsp;<div class='unit'>秒</div></div>
        </div>
      </div>
      <table class='overview-table'>
        <thead>
        <tr>
          <th>今开</th>
          <th>昨收</th>
        </tr>
        </thead>
        <tbody>
        <tr>
          <td :class='"text-" + useColorClass(stockBIndexData.increasePrice)'>
            <span v-if='stockBIndexData.openPrice'>{{stockBIndexData.openPrice}}</span>
            <span v-else>&nbsp;</span>
          </td>
          <td>
            <span v-if='stockBIndexData.yesterdayPrice'>{{stockBIndexData.yesterdayPrice}}</span>
            <span v-else>&nbsp;</span>
          </td>
        </tr>
        </tbody>
      </table>
      <table class='overview-table'>
        <tr>
          <th>最高</th>
          <td>最低</td>
          <td>振幅</td>
        </tr>
        <tr>
          <td :class='"text-" + useColorClass(stockBIndexData.increasePrice)'>
            <span v-if='stockBIndexData.maxTopPrice'>{{stockBIndexData.maxTopPrice}}</span>
            <span v-else>&nbsp;</span>
          </td>
          <td>
            {{stockBIndexData.minTopPrice}}
          </td>
          <td>
            <span v-if='stockBIndexData.amplitudeRate || stockBIndexData.amplitudeRate===0'>{{stockBIndexData.amplitudeRate.toFixed(2)}}%</span>
            <span v-else>&nbsp;</span>
          </td>
        </tr>
      </table>
    </div>
  </div>


  <div class='history-tab'>
    <h1 class='title-section'><img src='@/assets/history-white.svg' alt=''> 历史数据 </h1>
    <vue-awesome-paginate
        v-if='totalItems > useConfig().pageSize'
        :total-items='totalItems'
        :items-per-page='useConfig().pageSize'
        v-model='currentPage'
        backButtonClass='left-arrow'
        nextButtonClass='right-arrow'
        prev-button-content='&nbsp;'
        next-button-content='&nbsp;'
        :on-click='onClickHandler'
    />
    <div class='tab-content'>
      <div class='tab' :class='showTab === "time" ? "active":""' @click='showTab = "time"'>指数</div>
      <div class='tab' :class='showTab === "result" ? "active":""' @click='showTab = "result"'>独赢/总和大小/总和单双</div>
    </div>
    <table class='history-table bg-white'>
      <thead>
      <tr>
        <th>时间</th>
        <th v-if='showTab === "time"'>关盘指数 <br />({{stockAIndexData.stockName}})</th>
        <th v-if='showTab === "time"'>关盘指数 <br />({{stockBIndexData.stockName}})</th>
        <th v-if='showTab === "result"'>独赢</th>
        <th v-if='showTab === "result"'>总和大小</th>
        <th v-if='showTab === "result"'>总和单双</th>
      </tr>
      </thead>
      <tbody v-if='historyData?.length > 0'>
      <tr v-for='(history, index) in historyData' :key='"history" + index'>
        <td>{{history.battleDate}}</td>
        <td v-if='showTab === "time"'>
          <div class='flex flex-col justify-center items-center relative'>
            <span v-html='wrapLastDigit(history.mainLastPrice)'></span>
            <div class='price-difference' :class='"text-" + useColorClass(history.mainPriceFluctuation)'>
              <span :class='(history.mainPriceFluctuation) > 0  ? "rising" : (history.mainPriceFluctuation) === 0 ? "even" : "dropping"'></span>
              {{history.mainPriceFluctuation > 0  ? '+' : ''}}
              {{ history.mainPriceFluctuation}}<template v-if='history.mainPriceFluctuation === null'>0</template>

            </div>
          </div>
        </td>
        <td v-if='showTab === "time"'>
          <div class='flex flex-col justify-center items-center relative'>
            <span v-html='wrapLastDigit(history.customerLastPrice)'></span>
            <div class='price-difference' :class='"text-" + useColorClass(history.customerPriceFluctuation)'>
              <span :class='(history.customerPriceFluctuation) > 0  ? "rising" : (history.customerPriceFluctuation) === 0 ? "even" : "dropping"'></span>
              {{history.customerPriceFluctuation > 0  ? '+' : ''}}
              {{ history.customerPriceFluctuation}}<template v-if='history.customerPriceFluctuation === null'>0</template>
            </div>
          </div>

        </td>
        <td v-if='showTab === "result"'>{{history.digitsWinner}}</td>
        <td v-if='showTab === "result"'>{{history.totalBigSmall}}</td>
        <td v-if='showTab === "result"'>{{history.totalOddEven}}</td>
      </tr>
      </tbody>
      <tbody v-else-if='historyData?.length === 0 && stockAIndexData.openDateTime'>
      <tr>
        <td :colspan='showTab === "time"? 3 : 4' class='text-center'>
          暂无资料
        </td>
      </tr>
      </tbody>
      <tbody v-else>
      <tr v-for='item in new Array(6).fill({})' :key='item.index'>
        <td>
          <div class='flex justify-center items-center placeholder'>
            <div class='py-3 box w-16'></div>
          </div>
        </td>
        <td v-if='showTab === "time"'>
          <div class='flex justify-center items-center relative placeholder'>
            <div class='py-3 box w-16'></div>
          </div>
        </td>
        <td v-if='showTab === "time"'>
          <div class='flex justify-center items-center relative placeholder'>
            <div class='py-3 box w-16'></div>
          </div>
        </td>
        <td v-if='showTab === "result"'>
          <div class='flex justify-center items-center relative placeholder'>
            <div class='py-3 box w-10'></div>
          </div>
        </td>
        <td v-if='showTab === "result"'>
          <div class='flex justify-center items-center relative placeholder'>
            <div class='py-3 box w-10'></div>
          </div>
        </td>
        <td v-if='showTab === "result"'>
          <div class='flex justify-center items-center relative placeholder'>
            <div class='py-3 box w-10'></div>
          </div>
        </td>
      </tr>
      </tbody>
    </table>
  </div>
</div>
</template>

<style scoped>
.overview-row{
  display: flex;
  justify-content: space-evenly;
  margin:1rem .25rem;
}
.overview{
  background: #fff;
  box-shadow: 0 3px 8px #00000033;
  border: 1px solid #E1CAAA;
  border-radius: 10px;
  padding: 12px 5px 5px;
  margin: 0 .25rem;
  width: 50%;
}

.overview h1{
  font-size:16px;
  color: #1F3471;
  font-weight:bold;
  margin-bottom:8px;
}
.overview-table{
  background: #FBF1E3;
  width:100%;
  text-align:center;
  color:#707070;
  font-weight:bold;
  font-size:12px;
  margin: 6px 0;
}
.overview-table td, .overview-table th{
  border: 1px solid #E1CAAA;
  padding: 5px 0;
  width:1%;
  font-weight:bold;
}
@media(min-width:400px){
  .overview{padding: 12px 10px 5px;}
}
.datetime{
  display:block;
  color: #545454;
  font-size:12px;
}
.countdown-wrapper{
  display:flex;
  flex-wrap:wrap;
  justify-content: center;
  align-items: center;
  font-weight: bold;
  color:#545454;
  font-size:14px;
  margin: 15px 0 10px;
}
.countdown-timer{
  font-weight:bold;
  display:flex;
  justify-content: center;
  width:100%;
}
.countdown-timer > div{
  color: #1F3471;
  text-align:center;
  display:flex;
  font-size:14px;
}
.countdown-timer .unit{
  font-size: 14px;
  font-weight:normal;
  margin:0 2px;
}
.overview .price{font-size:20px;font-weight:bold;margin-top:8px;}
.difference{
  display:flex;
  justify-content: center;
  margin-top:2px;
  font-size:12px;
  gap:14px;
}

.rising-arrow, .dropping-arrow{
  position:relative;
}
.rising-arrow:before{
  content:'';
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 0 5px 5px 5px;
  border-color: transparent transparent #e42918 transparent;
  position:absolute;
  top:50%;
  transform: translate(-10px,-5px);
}
.rising-arrow span{
  width:1px;
  height:6px;
  background: #e42918;
  position:absolute;
  top:50%;
  transform: translate(-6px, -2px);
}
.dropping-arrow:before{
  content:'';
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 5px 5px 0 5px;
  border-color: #028431 transparent transparent transparent;
  position:absolute;
  top:50%;
  transform: translate(-18px, 0);
}
.dropping-arrow span{
  width:1px;
  height:6px;
  background: #028431;
  position:absolute;
  top:50%;
  transform: translate(-14px, -6px);
}
.history-tab{
  display:flex;
  flex-wrap:wrap;
}
.title-section {
  color: #A76E2D;
  border-left: 4px solid #EFD4AB;
  font-size: 16px;
  font-weight: bold;
  padding: 2px 5px;
  display: inline-flex;
  justify-content: space-between;
  align-items: center;
  gap:6px;
  margin:0 10px 10px;
}
.title-section img{width:20px;}
.history-table{
  width:100%;
  border-collapse: collapse;
}
.history-table th, .history-table td{
  width:1%;
  padding:5px 5px;
  text-align: center;
  border: 2px solid #EFD4AB;
  font-size: 14px;
  height:54px;

}
.history-table td{
  color:#545454;
}
.history-table tbody tr:nth-child(odd){
  background: #F8F5EA;
}
.history-table th{
  color: #A76E2D;
  white-space: nowrap;
  padding:0 10px;
}
.price-difference {
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  text-align:left;
}
.rising{
  margin-right:5px;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 0 6px 6px 6px;
  border-color: transparent transparent #e42918 transparent;
}
.dropping{
  margin-right:5px;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 6px 6px 0 6px;
  border-color: #028431 transparent transparent transparent;
}

.even{
  margin-right:12px;
  position:relative;
}
.even:after{
  content:'';
  width:8px;
  height:1px;
  background:#545454;
  position:absolute;
  left:0;
}
.even-symbol{
  width:20px;height:2px;background:#545454;
  transform: translate(calc(-50% + 10px), 0);
}
.tab-content{
  display:flex;
  width:100%;
  justify-content: space-evenly;
  color:#929292;
  margin-top:10px;
}
.tab-content .tab{
  padding:10px;
  border-bottom:2px solid transparent;
  width:100%;
  font-weight:bold;
  font-size:14px;
  text-align:center;
  cursor:pointer;
}
.tab-content .tab.active{border-color: #1F3471;color:#1F3471;}

.history-table td .placeholder{margin:0 10px;}
</style>
