<script setup>
import { onMounted } from 'vue';
onMounted((()=>{
  document.title = "全球指数-全方位指数统计网站 | 免责声明";
}))
</script>
<template>
  <div class='body-wrapper content-wrapper'>
    <div class='overview'>
      <h1>免责声明</h1>
      <p>
        任何人进入、浏览和使用全球指数（gf223.com）网站即承认已阅读、理解并同意受本使用条款约束，并遵守所有适用的法律和法规。若您不同意遵从本使用条款，请勿使用全球指数资讯网站（以下简称"本网站"）。全球指数有权随时更新本使用条款，您将受到更新后的使用条款的约束，因此您应经常地访问并了解本网站的使用条款。
      </p>

      <p>
        本网站提供的任何内容（包括但不限于数据、文字、图表、图象、声音或录象等）的版权均属于全球指数或相关权利人。未经全球指数或相关权利人事先的书面许可，您不得以任何方式擅自复制、再造、传播、出版、转帖、改编或陈列本网站的内容。同时，未经全球指数书面许可，对于本网站上的任何内容，任何人不得在非全球指数所属的服务器上做镜像。任何未经授权使用本网站的行为都将违反相关法律法规以及有关国际公约的规定。
      </p>

      <p>
        全球指数有权随时改变和更新本网站上的内容，不需要先行通知您本人。
      </p>

      <p>
        本网站内容除标明“全球指数出品”外，其余内容皆由内容服务提供商（机构）、网友提供，不能保证在任何时候、网站上的所有内容均正确、及时和完整；全球指数以及其分支机构、员工、代理以及其他任何代表（以下简称"相关人"）对于本网站内容的任何错误、不准确和遗漏以及使用本网站内容得出的结果都将不承担任何责任。全球指数及相关人不能保证您任何时候均可进入、浏览和使用本网站，对本网站和其内容的不能使用和使用错误不承担任何责任。
      </p>

      <p>
        任何情况下，全球指数及相关人对于进入或使用本网站引起的任何依赖本网站内容而作出的决定或采取的行动不承担任何责任，对进入或使用本网站而产生的任何直接的、间接的、惩罚性的损失或其他任何形式的损失包括但不限于业务中断、数据丢失或利润损失不承担任何责任。
      </p>
    </div>

  </div>



</template>
<style scoped>
h1{font-size:40px;text-align:center;margin-bottom:2rem;}
.content-wrapper{
  padding:1rem 5rem;
}
p{margin:1rem 0 1.5rem;}
.overview {
  background: #fff;
  box-shadow: 0 3px 8px #00000033;
  border: 3px solid #E1CAAA;
  border-radius: 10px;
  padding: 20px 40px;
  margin-top:2rem;
  line-height:1.8;
}
.mobile .content-wrapper{padding:10px;}
.mobile .overview{padding:10px 15px;}
.mobile h1{font-size:30px;margin-bottom:1.5rem;}
</style>
