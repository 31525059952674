<script setup >
import {ref, watch, onMounted} from 'vue'
import {useRoute, useRouter} from 'vue-router'
import Header from '@/components/desktop/Header';
import Footer from '@/components/desktop/Footer';
import MobileMenu from '@/components/mobile/MobileMenu';

import useMobile from '@/hook/useMobile';
const route = useRoute();
const router = useRouter();
const isOverlay = ref(false)
const modalContent = ref({title:'',content:''})
const showPopUp = ref(true)

const setModalContent = ({title, content}) => {
  modalContent.value.title = title;
  modalContent.value.content = content;
  isOverlay.value = true;
}

function closeModal(){
  modalContent.value.title = '';
  modalContent.value.content = '';
  isOverlay.value = false;
}

if (useMobile()){
  document.body.classList.add('mobile')
}else{
  document.body.classList.add('desktop')
}

const redirectDevicePath = async() =>{
  if (useMobile() && !/mobile/g.test(route.path)){
    await router.replace(`/mobile${route.path}`)
  }
  if(!useMobile() && (/mobile/g).test(route.path)){
    await router.replace(`${route.path.replace('/mobile/', '/')}`)
  }
}

const getCookie = (name) => {
  let cookie = {};
  document.cookie.split(';').forEach(function(el) {
    let [k,v] = el.split('=');
    cookie[k.trim()] = v;
  })
  return cookie[name];
}

const saveCookie =(name,value,days) => {
  let expires ='';
  if (days) {
    let date = new Date();
    date.setHours('23');
    date.setMinutes('59');
    date.setSeconds('59');
    date.setMilliseconds('999');
    date.setTime(date.getTime()+(days*24*60*60*1000));
    expires += ";expires="+date.toUTCString();
  }
  document.cookie = name + "=" + value + expires + "; path=/";
}

const closePopup = () => {
  showPopUp.value = false;
  saveCookie('popup', false, 1);
}
watch(() => route.path, ()=>{
  redirectDevicePath()
})

onMounted(async () => {
  await router.isReady()
  redirectDevicePath()
  if (getCookie('popup')) {
    showPopUp.value = false;
  }
})

</script>
<template>
  <MobileMenu v-if='useMobile()' />

  <div class='announcement-wrapper'>
    <marquee v-if='useMobile()'><img src='./assets/announcement.svg' alt=''> 以下指数自动更新，所有报价单位均为当地货币，行情时间均为北京时间，除非特殊说明，均延迟至少30分钟。
    </marquee>
    <div class='w-full text-center py-2 px-4 mb-2 announcement flex gap-3' v-else>
      <img src='./assets/announcement.svg' alt=''>
      以下指数自动更新，所有报价单位均为当地货币，行情时间均为北京时间，除非特殊说明，均延迟至少30分钟。
    </div>
  </div>
  <div id='app-wrapper'>
    <Header />
    <div class='domain-bg' v-if='$route.path !== "/" && !useMobile()'>
      <div>
        备用域名1 <a href='https://gf225.com' target='_blank'>gf225.com</a>
      </div>
      <div>
        备用域名2 <a href='https://gf226.com'  target='_blank'>gf226.com</a>
      </div>
    </div>

    <router-view @showModal='setModalContent' />
  </div>
  <Footer />

  <div class='overlay' @click='closeModal' v-show='isOverlay'></div>
  <div class='modal-wrapper' v-show='isOverlay'>
    <transition name='zoom'>
      <div class='modal'>
        <div class='modal-dialog'>
          <div class='modal-header'>
            <h1>{{modalContent.title}}</h1>
            <button @click='closeModal'><img src='./assets/close.svg' alt=''></button>
          </div>
          <div class='modal-content mt-10' v-html='modalContent.content'></div>
        </div>
      </div>
    </transition>
  </div>

  <div v-show='showPopUp' style='position:fixed;width:100%;height:100%;top:0;left:0;right:0;bottom:0;background:#000;opacity:.5;z-index:100'></div>

  <div class='overlay-domain'  v-show='showPopUp'>
    <div>
      <button @click='closePopup($event)'><img src='./assets/close-white.svg' alt=''></button>
      <div class='domain-column'>
        备用域名1
        <a href='https://gf225.com' target='_blank'>gf225.com</a>
      </div>
      <div class='domain-column'>
        备用域名2
        <a href='https://gf226.com'  target='_blank'>gf226.com</a>
      </div>
    </div>

  </div>
</template>


<style scoped lang="scss">
.announcement-wrapper:before{
  position:absolute;
  width:100%;
  height:35px;
  background: #f1f1f1;
  left:0;
  top:0;
  display:block;
  content:'';
  z-index:-1;
}
.announcement-wrapper{
  background: #f1f1f1;
  position:relative;
  width:100%;
  height:35px;
}
.mobile .announcement-wrapper{height:38px;}
.announcement{
  text-align: left;
  margin:0 auto;
  height:35px;
  display:flex;
  flex-wrap: nowrap;
  overflow-x: auto;
  overflow-y: hidden;
}
.desktop{
  .announcement{
    min-width:1200px;
    max-width:1440px;
  }
}
#app-wrapper{
  display:flex;
  flex-direction: column;
  flex:1 0 auto;
}
.overlay{
  position:fixed;
  background:#000;
  opacity:.4;
  width:100%;
  height:100%;
  top:0;
  bottom:0;
  left:0;
  right:0;
  z-index:100;
}
.modal{padding:20px;}
.modal-wrapper{
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1060;
  width: 100%;
  height: 100%;
  outline: 0;
  pointer-events: none;
}
.modal-dialog{
  position: relative;
  width: auto;
  margin: 1.5rem auto;
  max-width:1200px;
  overflow-x: hidden;
  overflow-y: auto;
  background-color: #fff;
  max-height: 90vh;
  border: 1px solid rgba(0,0,0,.2);
  border-radius: 0.3rem;
  outline: 0;
  padding:18px;
  pointer-events: all;

}
.modal-content {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
}
.modal-header{
  font-size: 36px;
  font-weight:bold;
  display:flex;
  justify-content: space-between;
  align-items: center;
}


.modal-header button{
  width:50px;
  height:50px;
}
.mobile .modal-header{
  width: calc(100% - 20px)
}
.mobile .modal-header button{
  width:30px;
  height:30px;
  position: absolute;
  top: 15px;
  right: 5px;
}
.mobile .modal-header{
  font-size:18px;
}
.mobile .modal-content{
  font-size:14px;
}
.mobile .modal-dialog{
  border-radius: 10px;
}
marquee{display:flex;flex-wrap: nowrap;align-items: center;padding:8px 0;}
marquee img{display:inline-flex;position:relative;top:-2px;margin-right:5px;}


.overlay-domain{
  top: 20vh;
  position: fixed;
  width: 100%;
  z-index:100;
  display:flex;
  font-size:14px;
  justify-content: center;
  padding:12px 10px;
  > div{
    position:relative;
    width: 100%;background:rgba(#2d050a, 90%);
    box-shadow: 0 3px 8px #00000044;
    border:2px solid #855944;
    padding:20px;
    display:flex;
    justify-content: center;
    gap:20px;
    border-radius:10px;}
  button{
    position:absolute;right:10px;top:10px;
    width:20px;
    height:20px;
    background:rgba(255,255,255,.4);
    border-radius:50%;
  }
  .domain-column{
    text-align:center;
    color:#f7c675;
    flex-direction:column;
    flex-wrap:wrap;
    display:flex;
    justify-content: center;
    padding: 10px 20px;
  }
  .domain-column:last-of-type{
    border-left:1px solid #74655e;
  }
  a{
    font-weight:bolder;
    color:#fff;
    display:block;
    margin:5px auto 0;
    text-decoration: underline;
  }

  @media(min-width: 992px){
    top: calc(20vh + 180px);
    left:50%;
    transform: translateX(-50%);
    font-size:18px;
    gap:40px;
    justify-content: center;
    padding:10px 16px;
    >div{      width:500px;
      border-radius: 20px;
      padding-top:30px;
      padding-bottom:30px;
    }
    button{
     right:10px;top:10px;
      width:25px;
      height:25px;
    }
    .domain-column{
      width:200px;
      padding:7px 15px;
    }
    a{margin-top:10px;}
  }
}
</style>
