<script setup>
import {
  ref,
  onMounted,
  onUnmounted,
  watch,
  defineExpose,
  defineProps,
} from 'vue';
import { createChart } from 'lightweight-charts';

const props = defineProps({
  type: {
    type: String,
    default: 'line',
  },
  data: {
    type: Array,
    required: true,
  },
  autosize: {
    default: true,
    type: Boolean,
  },
  chartOptions: {
    type: Object,
  },
  seriesOptions: {
    type: Object,
  },
  timeScaleOptions: {
    type: Object,
  },
  priceScaleOptions: {
    type: Object,
  },
  previousPriceLine: {
    type: Number
  },
  resultOption: {
    type: Number
  }
});

// Function to get the correct series constructor name for current series type.
function getChartSeriesConstructorName(type) {
  return `add${type.charAt(0).toUpperCase() + type.slice(1)}Series`;
}

// Lightweight Charts™ instances are stored as normal JS variables
// If you need to use a ref then it is recommended that you use `shallowRef` instead
let series;
let chart;

const chartContainer = ref();

const fitContent = () => {
  if (!chart) return;
 // chart.timeScale().fitContent();
};

const getChart = () => {
  return chart;
};

defineExpose({fitContent, getChart});

// Auto resizes the chart when the browser window is resized.
const resizeHandler = () => {
  if (!chart || !chartContainer.value) return;
  const dimensions = chartContainer.value.getBoundingClientRect();
  chart.resize(dimensions.width, dimensions.height);
};

// Creates the chart series and sets the data.
const addSeriesAndData = props => {
  const seriesConstructor = getChartSeriesConstructorName(props.type);
  series = chart[seriesConstructor](props.seriesOptions);

  if (props.data.length> 0){
    series.setData(props.data);
    document.querySelectorAll('.chart-overlay').forEach(el => {
      el.style.opacity = 0;
    });
  }else{
    document.querySelectorAll('.chart-overlay').forEach(el => {
      el.style.opacity = .8;
    });
  }


  if (props.type === 'area'){
    let topColor = '#f0877e';
    let lineColor = '#e95345';

    if (props.resultOption < 0){
      topColor = '#67b583'
      lineColor = '#0f8a3c'
    }
    series.applyOptions({
      lineColor,
      topColor,
      lineWidth: 1,
      bottomColor: 'white',
      priceScaleId: 'left',
      priceLineVisible:false,
      borderVisible: false,
    })
    chart.priceScale('right').applyOptions({
      borderColor: 'transparent',
    });
  }
  if (props.previousPriceLine){
    series.applyOptions({
      autoscaleInfoProvider: () => ({
        priceRange: {
          minValue: Math.min(...props.data.map(item=>item.value), props.previousPriceLine),
          maxValue: Math.max(...props.data.map(item=>item.value), props.previousPriceLine)
        },
      }),
    })
   let color = (props.resultOption < 0) ? '#028431' : '#D53A2C'

    series.createPriceLine({price:props.previousPriceLine,color: '#5B637A'})

    if (props.data && props.data.length > 0){
      series.createPriceLine({price:props.data[props.data.length-1].value, color});
    }
  }
  chart.timeScale().fitContent();

};

onMounted(() => {
  chart = createChart(chartContainer.value, props.chartOptions);

  addSeriesAndData(props);

  if (props.priceScaleOptions) {
    chart.priceScale().applyOptions(props.priceScaleOptions);
  }

  if (props.timeScaleOptions) {
    chart.timeScale().applyOptions(props.timeScaleOptions);
  }

  chart.timeScale().fitContent();

  if (props.autosize) {
    window.addEventListener('resize', resizeHandler);
  }
});

onUnmounted(() => {
  if (chart) {
    chart.remove();
    chart = null;
  }
  if (series) {
    series = null;
  }
  window.removeEventListener('resize', resizeHandler);
});

/*
 * Watch for changes to any of the component properties.

 * If an options property is changed then we will apply those options
 * on top of any existing options previously set (since we are using the
 * `applyOptions` method).
 *
 * If there is a change to the chart type, then the existing series is removed
 * and the new series is created, and assigned the data.
 *
 */
watch(
    () => props.autosize,
    enabled => {
      if (!enabled) {
        window.removeEventListener('resize', resizeHandler);
        return;
      }
      window.addEventListener('resize', resizeHandler);
    }
);

watch(
    () => props.type,
    () => {
      if (series && chart) {
        chart.removeSeries(series);
      }
      addSeriesAndData(props);
    }
);

watch(
    () => props.data,
    newData => {
      if (!series) return;
      series.setData(newData);
    }
);

watch(
    () => props.previousPriceLine,
    () => {
      if (series && chart) {
        chart.removeSeries(series);
      }
      addSeriesAndData(props);
    }
);

watch(
    () => props.chartOptions,
    newOptions => {
      if (!chart) return;
      chart.applyOptions(newOptions);
    }
);

watch(
    () => props.seriesOptions,
    newOptions => {
      if (!series) return;
      series.applyOptions(newOptions);
    }
);

watch(
    () => props.priceScaleOptions,
    newOptions => {
      if (!chart) return;
      chart.priceScale().applyOptions(newOptions);
    }
);

watch(
    () => props.timeScaleOptions,
    newOptions => {
      if (!chart) return;
      chart.timeScale().applyOptions(newOptions);
    }
);

watch(
    () => props.resultOption,
);
</script>

<template>
  <div className="lw-chart" ref="chartContainer"></div>
</template>

<style scoped>
.lw-chart {
  height: 100%;
}
</style>
