<template>
  <div class='footer-wrapper'>
    <div class='footer'>
      <div class='footer-link-wrapper'>
        <div class='logo'><router-link :to='"/"'>
          <img src='@/assets/img_logo_white.png' alt=''>
        </router-link></div>

        <div class='footer-link'>
          <router-link :to='"/"' class='flex gap-4'>
            <img src='@/assets/home.svg' alt=''> 首页
          </router-link>

          <router-link :to='{ name: "disclaimer"}' class='flex gap-4'>
            <img src='@/assets/disclaimer.svg' alt=''> 免责声明
          </router-link>
          <router-link :to='{ name: "about"}' class='flex gap-4'>
            <img src='@/assets/about.svg' alt=''> 关于我们
          </router-link>
        </div>
      </div>
    </div>
    <div class='text-center my-2 copyright'>&copy; Copyright 2023 gf223.com All rights reserved 全球指数 版权所有</div>

  </div>
</template>

<script>
export default {
  name: "Footer"
}
</script>

<style scoped>
a{padding:1rem;color:#fff;font-size:18px;font-weight:bold;white-space: nowrap;}
.footer-wrapper{
  margin-top:3rem;
  background:#190832;
}
.desktop .footer-wrapper{
  min-width:1200px;

}
.logo{width:370px;}
.logo a{padding:0}
.copyright{
  color: rgba(255,255,255,.3);
  font-size:12px;
  background: #211352;
  width:100%;
  margin:0;
  padding-top:1.5rem;
  padding-bottom:1.5rem;
}
.footer-link-wrapper{
  display:flex;
  width:92%;
  margin:0 auto;
  gap:10px;
  justify-content: center;
  align-items: center;
}
.mobile .footer-link-wrapper{
  flex-direction:column;
}
.mobile .logo{
  width:260px;
}
.footer-link{
  display:flex;
  flex:1;
  gap:4px;
  margin-left:120px;
}
.mobile .footer-link{
  margin-top:15px;
  margin-left:0;
  flex-direction: column;
}
</style>
