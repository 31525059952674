<script setup>
import { onMounted} from 'vue';
onMounted((()=>{
  document.title = "全球指数-全方位指数统计网站 | 关于我们";
}))
</script>
<template>
  <div class='body-wrapper content-wrapper'>
    <div class='overview'>

    <h1>关于我们</h1>
    <p>
      全球指数资讯网是一家致力于中国及世界各地指数事业，提供全方位指数内容及指数相关服务的提供商。
    </p>

    <p>
      全球指数资讯网以互联网技术、电信技术、无线应用技术为依托，将创新的技术与传统的指数产业相结合，面向全球指数服务市场，提供最快最全的指数相关服务，包括指数结果调用、指数系统的研发、指数结果移动客户端，及指数产品创新等。
    </p>

    <p>
      全球指数资讯网拥有一流的技术研发创新实力以及国内外多年的指数网站运营及管理经验， 为中国以及全球指数玩家提供一流的指数创新产品及优质的服务。全球指数资讯网本着专业、诚信、服务至上的经营理念， 充分发挥机动灵活的运营及管理机制，伴随着世界互联网及手机互联网时代的腾飞、指数市场的不断繁荣，和各方客户的忠诚支持，将全面促进新型指数服务市场的全盛发展。
    </p>

    </div>

  </div>

</template>
<style scoped>
h1{font-size:40px;text-align:center;margin-bottom:2rem;}
.content-wrapper{
  padding:1rem 5rem;
}
p{margin:1rem 0 1.5rem;}
.overview {
  background: #fff;
  box-shadow: 0 3px 8px #00000033;
  border: 3px solid #E1CAAA;
  border-radius: 10px;
  padding: 20px 40px;
  margin-top:2rem;
  line-height:1.8;
}
.mobile .content-wrapper{padding:10px;}
.mobile .overview{padding:10px 15px;}
.mobile h1{font-size:30px;margin-bottom:1.5rem;}
</style>

