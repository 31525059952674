<script setup>
import { defineProps } from 'vue';
import useColorClass from '@/hook/useColorClass';
import useStockProperty from '@/hook/useStockProperty';
import { format } from 'date-fns';

defineProps(['title', 'tableData'])

function isMarketOpened(openDate, closeTime) {
    if (new Date(openDate.replace(/-/g, "/")).getDate() === new Date().getDate()){
      let now = new Date();
      let closingDateTime = new Date( format(now, 'yyyy/MM/dd') + ' ' + closeTime)
      if (now.getTime() < closingDateTime.getTime() ){
        return true
      }
    }
  return false;
}
</script>

<template>
  <div class='analytic-table'  :class='"table-" + useColorClass(tableData.priceFluctuation)'>
    <div class='title-section'>
      <h1>{{title}}</h1>
      <template v-if='tableData.stockId'>
        <router-link  :to='"/market/"+useStockProperty(tableData.stockId, true).code' v-slot="{href}">
          <button :href="href">
            <img src='@/assets/history.svg' alt=''>历史数据
          </button>
        </router-link>
      </template>
    </div>

    <div class='content-wrapper'>
      <div class='block text-center datetime'>{{tableData.quotesDataTime}}</div>
      <div class='flex text-center justify-center items-center' :class='"text-" + useColorClass(tableData.priceFluctuation)'>
        <div class='price align-center'>{{tableData.lastPrice}}</div>

        <template v-if='tableData.priceFluctuation !== 0'>
          <div :class='(tableData.priceFluctuation > 0 ? "rising" : "dropping")+"-arrow"'><span></span></div>
        </template>
        <template v-else>
          <div class='even-symbol'><span></span></div>
        </template>
        <div class='flex flex-col difference justify-center text-left ml-6'>
          <div>{{tableData.priceFluctuation > 0 ? '+' : ''}}{{tableData.priceFluctuation}}</div>
          <div>{{tableData.priceFluctuation > 0 ? '+' : ''}}{{tableData.priceFluctuationRate}}%</div>
        </div>
      </div>
      <table>
        <tr>
          <td>今开</td>
          <td>昨收</td>
          <td>最高</td>
        </tr>
        <tr>
          <td :class='"text-" + useColorClass(tableData.priceFluctuation)'>
            {{tableData.openingPrice}}
          </td>
          <td>
            {{tableData.yesterdayClosingPrice}}
          </td>
          <td :class='"text-" + useColorClass(tableData.priceFluctuation)'>
            {{tableData.highPrice}}
          </td>
        </tr>
      </table>


      <table>
        <tr>
          <td>最低</td>
          <td>振幅</td>
          <td rowspan='2' v-if='tableData.quotesDataTime'>
            <template v-if='isMarketOpened(tableData.quotesDataTime, tableData.closeTime)'>已开盘</template>
            <template v-else>已收盘</template>
          </td>
        </tr>
        <tr>
          <td>
            <template v-if='tableData.lowPrice'>{{tableData.lowPrice}}</template>
          </td>
          <td>
            <template v-if='tableData.amplitudeRate  || tableData.amplitudeRate === 0'>{{tableData.amplitudeRate.toFixed(2)}}</template>%
          </td>
        </tr>
      </table>
    </div>
  </div>
</template>

<style scoped>
.title-section{
  color:#fff;
  font-size:22px;
  font-weight:bold;
  text-align:left;
  padding:18px 25px;
  display:flex;
  justify-content: space-between;
  align-items:center;
}
.table-green .content-wrapper{
  border: 3px solid #95D49E;
}
.table-green .title-section{
  background:url('../../assets/img_bg_title_green.jpg') no-repeat center -15px/106%;
}
.table-red .content-wrapper{
  border: 3px solid #EFB6B6;
}
.table-red .title-section{
  background:url('../../assets/img_bg_title_red.jpg') no-repeat center -15px/106%;
}
.table-black .content-wrapper{
  border: 3px solid #B2B2B2;
}
.table-black .title-section{
  background:url('../../assets/img_bg_title_gray.jpg') no-repeat center -15px/106%;
}
.analytic-table{
  box-shadow: 0 3px 8px #00000033;
  border-radius: 15px;
  overflow:hidden;
}
.analytic-table .content-wrapper{
  border-top:none;
  padding:10px 20px;
  border-bottom-left-radius: 15px;
  border-bottom-right-radius: 15px;
}
table{
  width: 100%;
  font-weight:bold;
  color:#707070;
  margin:15px 0;
}
table td{
  background: #DFDFDF;
  border-width: 1px;
  border-style: solid;
  border-color:#C4C4C4;
  padding:10px;
  width:30%;
}
.table-green table td{
  border-color:#ADE5B5;
  background: #DBF1DC;
}
.table-red table td{
  border-color:#F2B5B5;
  background: #F8E0E0;
}
button{
  background: #ffffffcc;
  color:#545454;
  padding:5px 10px;
  border-radius:4px;
  display:flex;
  align-items: center;
  font-size:15px;
}
button img{position:relative}
.datetime{font-size:16px;padding:1.25rem 0;}
.price{font-size:40px;font-weight:bold;width:180px;text-align:right}
.difference{font-size:18px;}
.rising-arrow, .dropping-arrow,.even-symbol{
  position:relative;
  margin-left:2rem;
  width:20px;
}
.rising-arrow:before{
  content:'';
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 0 10px 10px 10px;
  border-color: transparent transparent #e42918 transparent;
  position:absolute;
  top:50%;
  transform: translate(-10px, -10px);
}
.rising-arrow span{
  width:3px;
  height:10px;
  background: #e42918;
  position:absolute;
  top:50%;
  transform: translate(-2px, -2px);
}
.dropping-arrow:before{
  content:'';
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 10px 10px 0 10px;
  border-color: #028431 transparent transparent transparent;
  position:absolute;
  top:50%;
  transform: translate(-8px, 0);
}
.dropping-arrow span{
  width:3px;
  height:10px;
  background: #028431;
  position:absolute;
  top:50%;
  transform: translate(0, -10px);
}
.even-symbol{
  width:20px;height:2px;background:#545454;
  transform: translate(calc(-50% + 10px), 0);
}

.mobile .analytic-table{
  margin: 10px;
  font-size:14px;
}
.mobile h1{
  font-size:18px;
}
.mobile .title-section{padding:12px 15px;background-size:180%}
.mobile .datetime{padding:.5rem 0;font-size:15px;}
.mobile .price{font-size:30px;}
.mobile .difference{font-size:14px;}
.mobile .rising-arrow,.mobile .dropping-arrow{transform: scale(0.7)}
.mobile table{margin:0}
.mobile table:first-of-type{margin-top:.85rem}
.mobile table:last-of-type{margin-top:-1px}
.mobile td{text-align:center}
</style>
