<script setup>
import {onUnmounted, onMounted, ref, watch} from 'vue';
import useColorClass from '@/hook/useColorClass';
import { useRoute } from 'vue-router';
import axios from 'axios';
import useConfig from '@/hook/useConfig';
import useStockProperty from '@/hook/useStockProperty';
import {add, format} from 'date-fns';

const route = useRoute();
const { stockCode } = route.params;
const currentStock = ref(useStockProperty(stockCode))
const totalItems = ref(0);
const currentPage = ref(1);
const indexData = ref({})
const historyData = ref([])
const elapseTimer = ref({
  day:0,
  hours:0,
  minutes:0,
  seconds:0,
})
const showTab = ref('time');
let pollingId = 0;
let elapseId = 0;
let increment = 0;
function polling(){
  axios.get(`/api/StockResult/History?stockId=${currentStock.value.id}&pageIndex=${currentPage.value}&pageSize=${useConfig().pageSize}`).then(response=>{
    indexData.value = response.data.data.current
    historyData.value = response.data.data.history
    totalItems.value = response.data.data.recordCount
    if (increment === 0){
      elapseCloseTime();
    }
    increment++;
  })
  pollingId = setTimeout (polling, useConfig().interval)
}
const onClickHandler = (page) => {
  currentPage.value = page

  axios.get(`/api/StockResult/History?stockId=${currentStock.value.id}&pageIndex=${currentPage.value}&pageSize=${useConfig().pageSize}`).then(response=>{
    indexData.value = response.data.data.current
    historyData.value = response.data.data.history
    totalItems.value = response.data.data.recordCount
  })
};


watch(route, ({params}) => {
  currentStock.value = useStockProperty(params.stockCode);
  initialState();
});


function initialState(){
  increment = 0;
  indexData.value = {}
  historyData.value = []
  elapseTimer.value = {
    day:0,
    hours:0,
    minutes:0,
    seconds:0,
  }
  totalItems.value = 0;
  currentPage.value = 1;
  showTab.value = 'time'
  clearTimeout (pollingId);
  clearTimeout (elapseId)
  polling();
}

function elapseCloseTime(){
  let now = new Date();

  if (indexData.value.isOpening) {
    if (new Date(indexData.value.openDateTime.split('T')[0].replace(/-/g, "/")).getDate() === new Date().getDate()) {
      let closingDateTime = new Date( format(now, 'yyyy/MM/dd') + ' ' + indexData.value.closeTime)
      if (now.getTime() < closingDateTime.getTime()) {
        let totalSeconds = (closingDateTime.getTime() - now.getTime()) / 1000
        elapseTimer.value = {
          day: Math.floor(totalSeconds / 86400),
          hours: Math.floor(totalSeconds / 3600),
          minutes: Math.floor((totalSeconds % 3600) / 60),
          seconds: Math.floor((totalSeconds % 3600) % 60)
        }
        elapseId = setTimeout(elapseCloseTime, 1000)
      }else{
        let closingDateTime = new Date( format(add(now,{ days:1 }), 'yyyy/MM/dd') + ' ' + indexData.value.closeTime)
        let totalSeconds = (closingDateTime.getTime() - now.getTime()) / 1000
        elapseTimer.value = {
          day: Math.floor(totalSeconds / 86400),
          hours: Math.floor(totalSeconds / 3600),
          minutes: Math.floor((totalSeconds % 3600) / 60),
          seconds: Math.floor((totalSeconds % 3600) % 60)
        }
        elapseId = setTimeout(elapseCloseTime, 1000)
      }
    }
  }
}

const wrapLastDigit = (digit)=> {
  const string = digit.toString()
  if (/\./g.test(string)){
    const formattedString = digit.toFixed(2).toString()
    return [...formattedString].reverse().map((element, index) => (index === 3) ? `<strong class='text-blue'>${element}</strong>`: element).reverse().join('')
  }else{
    return [...string].reverse().map((element, index) => (index === 0) ? `<strong class='text-blue'>${element}</strong>`: element).reverse().join('')
  }
}

onMounted (()=>{
  polling()
  document.title = "全球指数-全方位指数统计网站 | "+currentStock.value?.name;
})
onUnmounted (() => {clearTimeout (pollingId);clearTimeout (elapseId)})
</script>
<template>
<div class='w-full body-wrapper'>
  <div class='overview'>
    <div class='text-center' v-if='indexData.openDateTime'>
      <h1>{{currentStock.name}}</h1>
      <div class='datetime'>{{indexData.openDateTime?.replace('T', ' ')}}</div>
    </div>
    <div v-else class='text-center placeholder'>
      <h1>{{currentStock.name}}</h1>
      <div class='box py-2 w-28'></div>
    </div>
    <div class='content-wrapper'>
      <div v-if='indexData.openPrice'>
        <div class='price text-center' :class='"text-"+useColorClass(indexData.increasePrice)'>{{indexData.lastPrice}}</div>

        <div class='difference' :class='"text-"+useColorClass(indexData.increasePrice)'>
          <template v-if='indexData.increasePrice !== 0'>
            <div :class='(indexData.increasePrice > 0 ? "rising" : "dropping")+"-arrow"'><span></span></div>
          </template>
          <div>{{indexData.increasePrice > 0 ? '+' : ''}}{{indexData.increasePrice.toFixed(2)}}</div>
          <div>{{indexData.increasePrice > 0 ? '+' : ''}}{{indexData.increaseRate}}%</div>
        </div>
      </div>
      <div class='placeholder' v-else>
        <div class='price text-center box w-1/5 py-4'></div>
        <div><span>&nbsp;</span></div>
        <div class='difference '>
          <div class='box w-24 py-4'></div>
          <div class='box w-16 py-4'></div>
        </div>
      </div>

      <div class='flex justify-center mt-5 mb-2'>
        <div class='countdown-wrapper'>
          距离收盘
          <div class='countdown-timer' v-if='indexData.closeTime'>
            <div>
              {{elapseTimer.day}}
              <div class='unit'>天</div>
            </div>

            <div>
              {{elapseTimer.hours}}
              <div class='unit'>时</div>
            </div>

            <div>
              {{elapseTimer.minutes}}
              <div class='unit'>分</div>
            </div>

            <div>
              {{elapseTimer.seconds}}
              <div class='unit'>秒</div>
            </div>
          </div>
          <div class='countdown-timer' v-else>
            <div>&nbsp;
              <div class='unit'>天</div>
            </div>
            <div>&nbsp;
              <div class='unit'>时</div>
            </div>
            <div>
              &nbsp;
              <div class='unit'>分</div>
            </div>
            <div>
              &nbsp;
              <div class='unit'>秒</div>
            </div>
          </div>
        </div>

      </div>

      <table class='overview-table'>
        <tr>
          <td>今开</td>
          <td>昨收</td>
          <td>最高</td>
          <td>最低</td>
          <td>振幅</td>
        </tr>
        <tr>
          <td :class='"text-" + useColorClass(indexData.increasePrice)'>
            {{indexData.openPrice}}
          </td>
          <td>
            {{indexData.yesterdayPrice}}
          </td>
          <td :class='"text-" + useColorClass(indexData.increasePrice)'>
            {{indexData.highPrice}}
          </td>
          <td>
            {{indexData.lowPrice}}
          </td>
          <td>
            <span v-if='indexData.amplitudeRate  || indexData.amplitudeRate === 0'>{{indexData.amplitudeRate.toFixed(2)}}%</span>
            <span v-else>&nbsp;</span>
          </td>
        </tr>
      </table>
    </div>
  </div>

  <div class='history-tab'>
    <h1 class='title-section'><img src='@/assets/history-white.svg' alt=''> 历史数据 </h1>
    <vue-awesome-paginate
        v-if='totalItems > useConfig().pageSize'
        :total-items='totalItems'
        :items-per-page='useConfig().pageSize'
        v-model='currentPage'
        backButtonClass='left-arrow'
        nextButtonClass='right-arrow'
        prev-button-content='&nbsp;'
        next-button-content='&nbsp;'
        :on-click='onClickHandler'
    />
    <div class='tab-content'>
      <div class='tab' :class='showTab === "time" ? "active":""' @click='showTab = "time"'>指数</div>
      <div class='tab' :class='showTab === "result" ? "active":""' @click='showTab = "result"'>单双/大小</div>
    </div>
    <table class='history-table bg-white'>
      <thead>
      <tr>
        <th>时间</th>
        <th v-if='showTab === "time"'>关盘指数</th>
        <th v-if='showTab === "result"'>单双</th>
        <th v-if='showTab === "result"'>大小</th>
      </tr>
      </thead>
      <tbody v-if='historyData?.length > 0'>
      <tr v-for='(history, index) in historyData' :key='"history" + index'>
        <td>{{history.date}}</td>
        <td v-if='showTab === "time"'>

          <div class='flex justify-center items-center relative'>
            <span class='mx-2' v-html='wrapLastDigit(history.lastPrice)'></span>
            <div class='price-difference mx-2' :class='"text-" + useColorClass(history.increasePrice)'>
              <span :class='history.increasePrice > 0  ? "rising" : history.increasePrice === 0 ? "ml-5" : "dropping"'></span>
              {{history.increasePrice >= 0  ? '+' : ''}}{{history.increasePrice}}
            </div>
          </div>

        </td>
        <td v-if='showTab === "result"'>{{history.oddEven}}</td>
        <td v-if='showTab === "result"'>{{history.bigSmall}}</td>
      </tr>
      </tbody>

      <tbody v-else>
      <tr v-for='item in new Array(6).fill({})' :key='item.index'>
        <td>
          <div class='flex justify-center items-center placeholder'>
            <div class='py-3 box w-1/2'></div>
          </div>
        </td>
        <td>
          <div class='flex justify-center items-center relative placeholder'>
            <div class='py-3 box w-40'></div>
            <div class='py-3 box w-20 absolute right-8'></div>
          </div>
        </td>
        <td>
          <div class='flex justify-center items-center relative placeholder'>
            <div class='py-3 box w-20'></div>
          </div>
        </td>
        <td>
          <div class='flex justify-center items-center relative placeholder'>
            <div class='py-3 box w-20'></div>
          </div>
        </td>
      </tr>
      </tbody>
    </table>
  </div>

</div>
</template>
<style scoped>
.overview{
  background: #fff;
  box-shadow: 0 3px 8px #00000033;
  border: 1px solid #E1CAAA;
  border-radius: 10px;
  padding: 12px 10px;
  margin:20px 5px;
}
.overview h1{
  font-size:16px;
  color: #1F3471;
  font-weight:bold;
  margin-bottom:8px;
}
.overview-table{
  background: #FBF1E3;
  width:100%;
  text-align:center;
  color:#707070;
  font-weight:bold;
  font-size:12px;
}
.overview-table td{
  border: 1px solid #E1CAAA;
  padding: 5px;
  width:1%;
  font-weight:bold;
}
.datetime{
  display:block;
  color: #545454;
  font-size:12px;
}
.countdown-wrapper{
  display:flex;
  align-items: center;
  font-weight: bold;
  color:#545454;
  font-size:14px;
}
.countdown-timer{
  font-weight:bold;
  display:flex;
  justify-content: center;
  gap:10px;
  margin-left:20px;
}
.countdown-timer > div{
  color: #1F3471;
  text-align:center;
  display:flex;
  font-size:14px;
}
.countdown-timer .unit{
  font-size: 14px;
  font-weight:normal;
  margin:0 8px;
}
.overview .price{font-size:20px;font-weight:bold;margin-top:8px;}
.difference{
  display:flex;
  justify-content: center;
  margin-top:2px;
  font-size:12px;
  gap:14px;
}

.rising-arrow, .dropping-arrow{
  position:relative;
}
.rising-arrow:before{
  content:'';
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 0 5px 5px 5px;
  border-color: transparent transparent #e42918 transparent;
  position:absolute;
  top:50%;
  transform: translate(-10px,-5px);
}
.rising-arrow span{
  width:1px;
  height:6px;
  background: #e42918;
  position:absolute;
  top:50%;
  transform: translate(-6px, -2px);
}
.dropping-arrow:before{
  content:'';
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 5px 5px 0 5px;
  border-color: #028431 transparent transparent transparent;
  position:absolute;
  top:50%;
  transform: translate(-18px, 0);
}
.dropping-arrow span{
  width:1px;
  height:6px;
  background: #028431;
  position:absolute;
  top:50%;
  transform: translate(-14px, -6px);
}
.history-tab{
  display:flex;
  flex-wrap:wrap;
}
.title-section {
  color: #A76E2D;
  border-left: 4px solid #EFD4AB;
  font-size: 16px;
  font-weight: bold;
  padding: 2px 5px;
  display: inline-flex;
  justify-content: space-between;
  align-items: center;
  gap:6px;
  margin:0 10px 10px;
}
.title-section img{width:20px;}
.history-table{
  width:100%;
  border-collapse: collapse;
}
.history-table th, .history-table td{
  width:1%;
  padding:15px 0;
  text-align: center;
  border: 2px solid #EFD4AB;
  font-size:14px;
}
.history-table td{
  color:#545454;
}
.history-table tbody tr:nth-child(odd){
  background: #F8F5EA;
}
.history-table th{
  color: #A76E2D;
}
.price-difference {
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  text-align:left;
}
.rising{
  margin-right:5px;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 0 6px 6px 6px;
  border-color: transparent transparent #e42918 transparent;
}
.dropping{
  margin-right:5px;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 6px 6px 0 6px;
  border-color: #028431 transparent transparent transparent;
}
.tab-content{
  display:flex;
  width:100%;
  justify-content: space-evenly;
  color:#929292;
  margin-top:10px;
}
.tab-content .tab{
  padding:10px;
  border-bottom:2px solid transparent;
  width:100%;
  font-weight:bold;
  font-size:14px;
  text-align:center;
  cursor:pointer;
}
.tab-content .tab.active{border-color: #1F3471;color:#1F3471;}
</style>
