<script setup>
import { ref, defineEmits, onMounted, onUnmounted } from 'vue';
import axios from 'axios';
import { getTimezoneOffset } from 'date-fns-tz';
import LWChart from '@/components/LWChart';
import HomepageTable from '@/components/common/HomepageTable';
import HomepageAnalytic from '@/components/common/HomepageAnalytic';
import useColorClass from '@/hook/useColorClass';
import useConfig from '@/hook/useConfig';
import { stockArray } from '@/hook/useStockProperty';


const chartOptions = ref({handleScale:false, handleScroll:false});
const timeScaleOptions = ref({
  timeVisible:true,
});

const seriesOptions = ref({
  color: '#3964DE',
  lastValueVisible: false,
});

const chartType = ref('line');

const lwChart = ref();

const tableStatus = ref();
const asiaStockExchange = ['日经225指数','韩国综合指数','香港恒生指数','上海综合指数','富时新加坡海峡时报指数','越南胡志明股票指数']
const asiaIndexes = ref([]);

const euroStockExchange = ['英国富时100指数','德国DAX 30种股价指数','法CAC40指数','瑞士股票指数','西班牙IBEX指数','荷兰AEX综合指数']
const euroIndexes = ref([]);

const americaStockExchange = ['道琼斯','标准普尔500','纳斯达克','加拿大S&P/TSX综合指数','墨西哥BOLSA指数','巴西BOVESPA股票指数']
const americaIndexes = ref([]);

const pacificStockExchange = ['澳大利亚标准普尔200指数','新西兰NZSE 50指数','澳交所普通股指数']
const pacificIndexes = ref([]);

const mastheadArray = stockArray.reduce((result, item) =>  ({...result, [item.region]: [...(result[item.region] || []) , (item.region && item.sliderName) && item,], }) , {})
const analyticTable = ref([]);

const newslist = ref({})

axios.get('/api/News/GetNewsData?count=5').then(response => {
  newslist.value = response.data.data
})

const emit = defineEmits(['showModal'])
function triggerNews(index){
  emit('showModal',{title: newslist.value[index].title, content:newslist.value[index].contents})
}
const chartData = ref([])

function timeToLocal(originalTime) {
  const d = new Date((originalTime ) * 1000);
  return Date.UTC(d.getFullYear(), d.getMonth(), d.getDate(), d.getHours(), d.getMinutes(), d.getSeconds(), d.getMilliseconds()) / 1000;
}

function populateChartData(chart){
  const unique = [...new Map(graphArray.value.filter((stock)=>  stock.stockId === chart.value.stockId).map(item =>[item['time'], item])).values()];
  const offset = getTimezoneOffset(stockArray.find(obj=>obj.id === chart.value.stockId).timeZone) * 0.001
  return unique.map( stock => ({ time: timeToLocal((new Date(stock.time).getTime()/1000) + offset - 28800), value: stock.price}))
}

let activeSlide = ref('')
let activeChartTab = ref('asia')
let activeAnalyticTable = ref('asia')
let activeChart = ref({})
let activeChartData = ref([])
let activeChartPrevious = ref(0)
let graphArray = ref([]);

function changeSlide(slide){
  if (activeSlide.value === slide) return
  activeSlide.value = slide
  activeChart.value = chartData.value.find(chart => chart.stockId===slide);
  activeChartPrevious.value = activeChart.value.yesterdayPrice;
  activeChartData.value = populateChartData(activeChart)

}

function toggleChartTab(tab){
  activeChartTab.value = tab
  if (tab === 'asia'){
    changeSlide(1)
  }else{
    changeSlide(7)
  }
}

const findChartDataById = (id) => chartData.value.find(chart => chart.stockId === id)

let pollingId = 0
let increment = 0;
!(async function f() {
  setTimeout(()=>{

  axios.get('/api/StockResult/GetHot').then(response=>{
    chartData.value = response.data.data.hotStock
    graphArray.value = response.data.data.lineGraph;
    if (increment ===0){
      changeSlide(1)
    }
    increment++;
  })

  axios.get('/api/StockResult/CurrentContinentsPriceList').then(response=>{
    tableStatus.value = response.data.isSuccess;
    asiaIndexes.value = response.data.data.find(({continentId}) => continentId === 1).indexItem.map((stock, index)=> {return { name: asiaStockExchange[index], ...stock}})
    euroIndexes.value = response.data.data.find(({continentId}) => continentId === 2).indexItem.map((stock, index)=> {return { name: euroStockExchange[index], ...stock}})
    americaIndexes.value = response.data.data.find(({continentId}) => continentId === 3).indexItem.map((stock, index)=> {return { name: americaStockExchange[index], ...stock}})
    pacificIndexes.value = response.data.data.find(({continentId}) => continentId === 4).indexItem.map((stock, index)=> {return { name: pacificStockExchange[index], ...stock}})
  })
  axios.get('/api/StockResult/CurrentIndexDetailPriceList').then(response=>{
    analyticTable.value = response.data.data
  })
  }, 100)
  pollingId = setTimeout (f, useConfig().interval)
}) ()
onMounted((()=>{
  document.title = "全球指数-全方位指数统计网站 | 首页";
}))
onUnmounted (() => clearTimeout (pollingId))
</script>
<template>
  <div class='masthead-bg'>
  <div class='masthead-region'>
    <div class='masthead flex w-2/3'>
      <div class='masthead-list w-1/3'>
        <div class='section-title'><img src='../../assets/ico_fire.svg' alt=''>熱門指數</div>
        <template v-if='chartData.length > 0'>
          <template v-for='chart in mastheadArray[activeChartTab]' :key='chart.code'>
            <div @click='$router.push("/market/"+chart.code)' @mouseover='changeSlide(chart.id)' class='hover-slide cursor-pointer' :class='{"active-slide": activeSlide === chart.id }'>
              <div class='hover-info'>
                <strong>{{chart.sliderName}}</strong>
                <strong :class='"text-" + useColorClass(findChartDataById(chart.id).increasePrice)'>
                  {{ findChartDataById(chart.id).lastPrice }}
                </strong>
              </div>
              <div class='hover-difference' :class='"text-" + useColorClass(findChartDataById(chart.id).increasePrice)'>
                <span :class='findChartDataById(chart.id).increasePrice > 0  ? "rising" : "dropping"'></span>
                {{ findChartDataById(chart.id).increasePrice > 0 ? '+':''}}
                {{ findChartDataById(chart.id).increasePrice }}
              </div>
            </div>
          </template>
        </template>

        <template v-else v-for='item in new Array(4).fill({})' :key='item.index'>
          <div class='hover-slide placeholder'>
            <div class='hover-info items-center'>
              <strong class='box w-1/2 py-3 mb-1'></strong>
              <strong class='box w-24 pt-2 pb-3'>
              </strong>
            </div>
            <div class='hover-difference'>
              <div class='box w-1/2 py-3 mr-2'></div>
              <div class='box w-1/3 py-3'></div>
            </div>
          </div>
        </template>
      </div>


      <div class=' w-2/3' >
        <div class='chart-tab'>
          <div @click='toggleChartTab("asia")' :class='{ active: activeChartTab ==="asia" }'>亚洲</div>
          <div @click='toggleChartTab("euro")' :class='{ active: activeChartTab ==="euro" }'>欧洲</div>
        </div>
        <div class='masthead-chart'>
          <div class='chart-info' v-if='activeChart.stockId'>
            <div class='chart-info-overview'>
              <div class='total-price' :class='"text-" + useColorClass(activeChart.increasePrice)'>
                {{activeChart.lastPrice}}
              </div>
              <div class='price-difference' :class='"text-" + useColorClass(activeChart.increasePrice)'>
                <span :class='activeChart.increasePrice > 0  ? "rising" : "dropping"'></span>
                {{activeChart.increasePrice}} ({{ activeChart.increaseRate?.toFixed(2) }}%)
              </div>
            </div>
            <div class='chart-analysis'>
              <div>
                <div class='label'>开盘</div>
                <strong class='price'>{{activeChart.openPrice}}</strong>
              </div>

              <div>
                <div class='label'>最高</div>
                <strong class='price'>{{activeChart.highPrice}}</strong>
              </div>

              <div>
                <div class='label'>最低</div>
                <strong class='price'>{{activeChart.lowPrice}}</strong>
              </div>

              <div>
                <div class='label'>昨收</div>
                <strong class='price'>{{activeChart.yesterdayPrice}}</strong>
              </div>
            </div>
          </div>
          <div v-else class='chart-info'>
            <div class='chart-info-overview placeholder mt-1'>
              <div class='text-center total-price'>
                <div class='pl-20 w-1/2 box mr-2 py-5'></div>
              </div>
              <div class=' w-16 box py-3 mr-2 mb-2'></div>
              <div class=' w-12 box py-3 mb-2'></div>
            </div>
            <div class='chart-analysis placeholder mb-1'>
              <div>
                <div class='label'>开盘</div>
                <strong class='box py-3 w-20'></strong>
              </div>

              <div>
                <div class='label'>最高</div>
                <strong class='box py-3 w-20'></strong>
              </div>

              <div>
                <div class='label'>最低</div>
                <strong class='box py-3 w-20'></strong>
              </div>

              <div>
                <div class='label'>昨收</div>
                <strong class='box py-3 w-20'></strong>
              </div>
            </div>

          </div>
          <div class='chart-wrapper' v-if='graphArray.length > 0 && activeChartPrevious'>
            <LWChart
                :type="chartType"
                :data="activeChartData"
                :autosize="true"
                :chart-options="chartOptions"
                :series-options="seriesOptions"
                :previous-price-line='activeChartPrevious'
                :time-scale-options="timeScaleOptions"
                :result-option='activeChart.increasePrice'
                ref="lwChart"
            />
            <div class='chart-overlay'>暂无资料</div>
          </div>
          <div class='placeholder' v-else>
            <div class='box' style='width:564px;height:215px'></div>
          </div>
        </div>

      </div>
    </div>
    <div class='masthead-news'>
      <h4 class='news-title'><img src='../../assets/ico_news.svg' alt=''>今日要闻</h4>

      <div class='news-wrapper' v-if='newslist?.length > 0'>
        <div class='headline cursor-pointer' v-if='newslist[0]' @click='triggerNews(0)'>
          <div class='content text-left flex-auto flex'>
            <h2 class='single-news-title'>{{newslist[0].title}}</h2>
            <p class='news-time ml-auto mr-0 mt-auto mb-1 pb-1'>
              {{new Intl.DateTimeFormat('zh-CN', { dateStyle: 'short'}).format(new Date(newslist[0].createdTime)) }}
            </p>
          </div>
        </div>

        <template v-for='news in 4' :key="'news-' + news">
          <div class='news-content cursor-pointer' @click='triggerNews(news)'>
            <h6 class='single-news-title'>{{newslist[news].title}}</h6>
            <p class='news-time'>
              {{new Intl.DateTimeFormat('zh-CN', { dateStyle: 'short' }).format(new Date(newslist[news].createdTime)) }}
            </p>
          </div>
        </template>
      </div>

      <div class='news-wrapper placeholder' v-else>
        <div class='headline'>
          <div class='content text-left flex-auto flex flex-col gap-5'>
            <h6 class='box py-4 mt-2 w-4/5'></h6>
            <p class='ml-auto mr-0 box py-3 px-12 mb-1'></p>
          </div>
        </div>

        <template v-for='news in 4' :key="'placeholder-news-' + news">
          <div class='news-content flex-auto flex flex-col gap-1 my-2' style='margin-bottom:0.5rem'>
            <h6 class='box py-4' :class='"w-"+ (Math.round( Math.random()* 1) + 1)+"/3"'></h6>
            <p class='ml-auto mr-0 box py-2 px-12 mb-1'>
            </p>
          </div>
        </template>
      </div>

    </div>
  </div>
  </div>
  <div class='domain-bg'>
    <div>
      备用域名1 <a href='https://gf225.com' target='_blank'>gf225.com</a>
    </div>
    <div>
      备用域名2 <a href='https://gf226.com'  target='_blank'>gf226.com</a>
    </div>
  </div>
  <div class='notification body-wrapper'>以下指数自动更新，所有报价单位均为当地货币，行情时间均为北京时间，除非特殊说明，均延迟至少30分钟。</div>

  <div class='text-center bottom-bg'>
    <div class='body-wrapper'>
      <div class='row two-column flex'>
        <HomepageTable :title='"亚洲股市"' :tableData='asiaIndexes' placeholder='6' v-if='tableStatus !== false' />
        <HomepageTable :title='"欧洲股市"' :tableData='euroIndexes' placeholder='6' v-if='tableStatus !== false' />
      </div>

      <div class='row two-column flex'>
        <HomepageTable :title='"美洲股市"' :tableData='americaIndexes' placeholder='6' v-if='tableStatus !== false' />
        <HomepageTable :title='"澳洲股市"' :tableData='pacificIndexes' placeholder='6' empty='3' v-if='tableStatus !== false' />
      </div>
    </div>
  </div>
  <div class='text-center'>
    <div class='body-wrapper'>
      <div class='analytic-table-tab'>
        <div @click='activeAnalyticTable = "asia"' :class='{ active: activeAnalyticTable ==="asia" }'>亚洲</div>
        <div @click='activeAnalyticTable = "euro"' :class='{ active: activeAnalyticTable ==="euro" }'>欧洲</div>
      </div>

      <div class='grid grid-cols-2 gap-x-5 gap-y-12' v-if='analyticTable.length > 0 && activeAnalyticTable === "asia"'>
        <HomepageAnalytic :title='"上海综合指数"' :tableData='analyticTable[3]' />
        <HomepageAnalytic :title='"日经225指数"' :tableData='analyticTable[0]' />
        <HomepageAnalytic :title='"韩国综合指数"' :tableData='analyticTable[1]' />
        <HomepageAnalytic :title='"香港恒生指数"' :tableData='analyticTable[2]' />
      </div>

      <div class='grid grid-cols-2 gap-x-5 gap-y-12' v-if='analyticTable.length > 0 && activeAnalyticTable === "euro"'>
        <template v-if='analyticTable[4] || analyticTable[5] || analyticTable[6] || analyticTable[7]'>
          <template v-if='analyticTable[4]'><HomepageAnalytic :title='"英国富时100指数"' :tableData='analyticTable[4]' /></template>
          <template v-if='analyticTable[5]'><HomepageAnalytic :title='"德国DAX30指数"' :tableData='analyticTable[5]' /></template>
          <template v-if='analyticTable[6]'><HomepageAnalytic :title='"法国CAC40指数"' :tableData='analyticTable[6]' /></template>
          <template v-if='analyticTable[7]'><HomepageAnalytic :title='"荷兰AEX综合指数"' :tableData='analyticTable[7]' /></template>
        </template>
        <div v-else class='relative'>
          <HomepageAnalytic :title='"..."' :tableData='{priceFluctuation: 0, priceFluctuationRate: 0}' />
          <div class='blur-overlay'>暂无资料</div>
        </div>
      </div>
    </div>
  </div>
</template>


<style scoped>
.desktop .row .homepage-table:last-of-type {
  margin-left: 1.25rem;
}
.price{color:#545454;}

.masthead-list > div{padding-top:1.25rem;padding-bottom:1.25rem;text-align:center;}
.masthead-list{height:100%;display:flex;flex-direction: column;}
.masthead-bg{
  min-width:1200px;
  position:relative;width:100%;overflow:hidden;margin-top:1rem;}
.masthead-bg:before{
    position:absolute;
    width:100%;
    height:100%;
    background:#D9b268 url('../../assets/masthead_bg.png') no-repeat center top/cover;
    display:block;
    left:0;
    content:'';
    z-index:-1;
}
.masthead-region{
  display:flex;
  position:relative;
  min-width:1200px;
  max-width:1440px;
  margin:.55rem auto;
  width:100%;
}

.masthead{
  display:flex;
}
.section-title img{margin-top:-2px;}
.section-title{
  height:62px;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  border: 3px solid #EC6E6E;
  border-bottom: 2px solid #E2DAD0;
  display:flex;
  justify-content: center;
  align-items: center;
  font-size:18px;
  color: #BF4141;
  background: #fff;
}
.chart-tab{
  margin-top:15px;
  font-size: 16px;
  color: #929292;
  width:50%;
  display:flex;
  justify-content: space-evenly;
}
.chart-tab .active{color:#000;}
.chart-tab > div{
  border-right:2px solid transparent;
  background: #f5efef;
  width:100%;
  text-align:center;
  margin-right:1px;
  padding:10px;
  cursor:pointer;
}
.chart-tab > div:last-of-type{
  border-radius: 0 8px 0 0;
}
.masthead-chart{
  position: relative;
  background: transparent linear-gradient(180deg, #FFFFFF 0%, #FFE4E4 100%) 0% 0% no-repeat padding-box;
  box-shadow: 0 1px 5px #00000033;
  border: 3px solid #EC6E6E;
  border-top-right-radius: 17px;
  border-bottom-right-radius: 17px;
  border-left:none;
  display:flex;
  flex-direction:column;
  align-items: center;
  justify-content: flex-end;
  padding:20px 32px 24px;
}
.hover-info{
  display: flex;
  flex-direction:column;
  width:42%;
}
.hover-slide{
  background: #efefef ;
  border-left: 3px solid #EC6E6E;
  border-bottom: 2px solid #E2DAD0;
  gap:10px;
  height:96px;
}
.hover-slide:last-of-type{
  border-bottom: 3px solid #EC6E6E;
}
.active-slide{
  background: #fff;
}
.hover-slide, .hover-difference{
  display:flex;
  justify-content: center;
  align-items: center;
  font-size:18px;
}
.hover-difference{}
.rising{
  margin-right:5px;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 0 6px 6px 6px;
  border-color: transparent transparent #e42918 transparent;
}
.dropping{
  margin-right:5px;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 6px 6px 0 6px;
  border-color: #028431 transparent transparent transparent;
}

.masthead-news{
  display:flex;
  flex-direction: column;
  width:33.3333%;
  margin-left: 20px;
}
.news-title img{margin-top:-2px;}
.news-title{
  color:#D67D4F;
  font-size:18px;
  height:55px;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  border: 3px solid #fff;
  background: #fff;
  width:50%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 1rem;
  padding-bottom: 1rem;
}
.news-wrapper{
  padding:10px 15px;
  background: #fff;height:100%;
  border-radius:10px;
  border-top-left-radius: 0;
  display: flex;
  flex-direction: column;
}
.headline{
  display:flex;
  flex:1;
  gap:1rem;
  margin:.25rem 0 0;
  border-bottom:1px solid #eee;
}
.headline .single-news-title{
  padding-top:.5rem;
  -webkit-line-clamp: 1;
  height:0;
  padding-bottom:32px;
  position:absolute;
  -webkit-box-orient: vertical;
  text-overflow: ellipsis;
  overflow:hidden;
  display: -webkit-box;
  font-size:18px;
  font-weight:bold;
  line-height:1.8rem;
}
.headline .news-time{
  margin-bottom:0;
  margin-top:auto;
}
.news-content{
  margin-bottom:.8rem;
  padding-bottom:2px;
  border-bottom:1px solid #eee;
}
.news-content .single-news-title{
  font-size: 16px;
  line-height: 1.2rem;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  margin:.5rem 0;
  text-overflow: ellipsis;
  overflow:hidden;
  height:20px;
  font-weight:bold;
}
.news-time{
  text-align:right;
  color: #8A8A8A;
  font-size:12px;
}
.chart-wrapper{
  position:relative;
  height:220px;
  width:100%;
}
.chart-info{width:100%;}
.chart-info-overview{
  display: flex;
  align-items: flex-end;
  justify-content: center;
  width:100%;
}
.total-price{
  font-size: 40px;
  font-weight: bold;
  flex:1;
  text-align:center;
}
.price-difference{
  display:flex;
  flex-wrap:nowrap;
  align-items: center;
  margin-left:auto;
  margin-right:0;
  text-align: right;
  margin-bottom:8px;
  font-weight:bold;
}
.chart-analysis{
  display:flex;
  justify-content: space-around;
  text-align:right;
  margin: .25rem 0 .5rem;
}
.chart-analysis .label{
  color: #707070;
  font-size:14px;
}
.notification{
  text-align: center;
  width:100%;
  padding:10px;
  color:#707070;
  background: #F3EDE4;
  margin: .7rem auto;
}
.bottom-bg{
  background:url('../../assets/bottombg.png') no-repeat center top/cover;
}

.two-column > div{
  width:50%;
  margin-bottom: 1rem;
}
.row{margin-bottom:2rem;}
.analytic-table-tab{display:flex;color:#929292;margin-bottom:2rem;}
.analytic-table-tab > div{padding:10px 60px;cursor:pointer;border-bottom:2px solid #cecece;font-weight:bolder;}
.analytic-table-tab .active{color:#2E2E2E;border-bottom-color:#c79033;}

.chart-overlay{
  opacity:0;
  pointer-events:none;
  transition:all .15s;
  width:100%;
  height:100%;
  position:absolute;
  top:0;left:0;
  background:rgba(0,0,0,.5);
  z-index:2;
  display:flex;
  align-items: center;
  justify-content: center;
  color:#fff;
  font-size:16px;
  backdrop-filter: blur(4px);
}
.blur-overlay{
  position:absolute;
  width:100%;
  height:100%;
  background:rgba(0,0,0,.5);
  z-index:2;
  display:flex;
  align-items: center;
  justify-content: center;
  color:#fff;
  font-size:16px;
  top:0;left:0;
  backdrop-filter: blur(2px);
  border-radius: 15px;
}
</style>
