<script setup>
import {onMounted, ref, watch} from 'vue';
import {useRoute} from 'vue-router';
const isMenuOpen = ref(true);
const isCollapsed = ref(false);
const isSubCollapsed = ref(false);
const route = useRoute();
const toggleMenu = () => {
  isMenuOpen.value = !isMenuOpen.value
  if(isMenuOpen.value){
    document.body.classList.add('overflow-hidden')
  }else{
    document.body.classList.remove('overflow-hidden')
  }

}
const expandMenu = ref('');
const expandSubMenu = ref('');

const menus = {
  asia: ref(null),
  america: ref(null),
  euro: ref(null),
  aus: ref(null),
  battle: ref(null),
}

const submenus = {
  asia: ref(null),
  euro: ref(null),
}
const menuHeight = ref([])
const subMenuHeight = ref([])

const toggleExpandMenu = async(menu) => {
  if (expandMenu.value !== menu){
    expandMenu.value = menu;
  }else{
    expandMenu.value = ''
  }
}

const toggleExpandSubMenu = async(submenu) => {
  if (expandSubMenu.value !== submenu){
    expandSubMenu.value = submenu;
  }else{
    expandSubMenu.value = ''
  }
}
const closeMenu = () => {
  isMenuOpen.value = false
}

onMounted (() => {
  menuHeight.value.push(menus.asia.value?.clientHeight, menus.america.value?.clientHeight, menus.euro.value?.clientHeight, menus.aus.value?.clientHeight, menus.battle.value?.clientHeight)
  subMenuHeight.value.push(submenus.asia.value?.clientHeight, submenus.euro.value?.clientHeight)

  setTimeout(()=>{
    isMenuOpen.value = false
    isCollapsed.value = true
    isSubCollapsed.value = true
    toggleExpandMenu('asia')
    toggleExpandSubMenu('asia')
  },1)
})
watch(() => route.path, ()=>{
  isMenuOpen.value = false
  document.body.classList.remove('overflow-hidden')
})
</script>
<template>
<div class='flex justify-center' >
  <router-link to='/mobile/'>
    <img src='../../assets/img_logo.png' alt='' ref='testmenu' style='width:180px;' />
  </router-link>
  <button class='absolute right-3 top-3 hamburger-container p-3' @click='toggleMenu' :class='isMenuOpen ? "active": ""'>
    <span class="top"></span>
    <span class="middle"></span>
    <span class="bottom"></span>
  </button>

  <div v-if='isMenuOpen' class='overlay-menu-wrapper'>
    <div class='overlay-menu'>

    <router-link class='menu-item' :to='"/mobile/"'>首頁</router-link>
    <div class='menu-item expand-item' :class='{ "is-expand": expandMenu === "asia" }' @click='toggleExpandMenu("asia")'>亚洲</div>

    <div :ref='menus.asia' class='expand-menu' :class='{ "is-collapsed": isCollapsed }' :style="expandMenu === 'asia' ? 'height:'+menuHeight[0]+'px'  : ''">
      <router-link class='submenu-item' :to='{ name: "MobileMarket", params: { stockCode: "000001.SS"}}' @click='closeMenu'>上海综合</router-link>
      <router-link class='submenu-item' :to='{ name: "MobileMarket", params: { stockCode: "KS11"}}' @click='closeMenu'>韩国综合</router-link>
      <router-link class='submenu-item' :to='{ name: "MobileMarket", params: { stockCode: "HSI"}}' @click='closeMenu'>香港恒生</router-link>
      <router-link class='submenu-item' :to='{ name: "MobileMarket", params: { stockCode: "N225"}}' @click='closeMenu'>日经指数</router-link>
      <router-link class='submenu-item' :to='{ name: "MobileMarket", params: { stockCode: "STI"}}' @click='closeMenu'>富时新加坡海峡时报指数</router-link>
      <router-link class='submenu-item' :to='{ name: "MobileMarket", params: { stockCode: "VNI"}}' @click='closeMenu'>越南胡志明股票指数</router-link>
    </div>

    <div class='menu-item expand-item' :class='{ "is-expand": expandMenu === "america" }' @click='toggleExpandMenu("america")'>美洲</div>

    <div :ref='menus.america' class='expand-menu' :class='{ "is-collapsed": isCollapsed }' :style="expandMenu === 'america' ? 'height:'+menuHeight[1]+'px'  : ''">
      <router-link class='submenu-item' :to='{ name: "MobileMarket", params: { stockCode: "DJI"}}' @click='closeMenu'>道琼斯</router-link>
      <router-link class='submenu-item' :to='{ name: "MobileMarket", params: { stockCode: "GSPC"}}' @click='closeMenu'>标准普尔500</router-link>
      <router-link class='submenu-item' :to='{ name: "MobileMarket", params: { stockCode: "IXIC"}}' @click='closeMenu'>纳斯达克</router-link>
      <router-link class='submenu-item' :to='{ name: "MobileMarket", params: { stockCode: "TSX"}}' @click='closeMenu'>加拿大S&P/TSX综合指数</router-link>
      <router-link class='submenu-item' :to='{ name: "MobileMarket", params: { stockCode: "MXX"}}' @click='closeMenu'>墨西哥BOLSA指数</router-link>
      <router-link class='submenu-item' :to='{ name: "MobileMarket", params: { stockCode: "BVSP"}}' @click='closeMenu'>巴西BOVESPA股票指数</router-link>
    </div>

    <div class='menu-item expand-item' :class='{ "is-expand": expandMenu === "euro" }' @click='toggleExpandMenu("euro")'>欧洲</div>

    <div :ref='menus.euro' class='expand-menu' :class='{ "is-collapsed": isCollapsed }' :style="expandMenu === 'euro' ? 'height:'+menuHeight[2]+'px'  : ''">
      <router-link class='submenu-item' :to='{ name: "MobileMarket", params: { stockCode: "FTSE"}}' @click='closeMenu'>英国富时100指数</router-link>
      <router-link class='submenu-item' :to='{ name: "MobileMarket", params: { stockCode: "GDAXI"}}' @click='closeMenu'>德国DAX 30种股价指数</router-link>
      <router-link class='submenu-item' :to='{ name: "MobileMarket", params: { stockCode: "CAC"}}' @click='closeMenu'>法CAC40指数</router-link>
      <router-link class='submenu-item' :to='{ name: "MobileMarket", params: { stockCode: "SMI"}}' @click='closeMenu'>瑞士股票指数</router-link>
      <router-link class='submenu-item' :to='{ name: "MobileMarket", params: { stockCode: "IBEX"}}' @click='closeMenu'>西班牙IBEX指数</router-link>
      <router-link class='submenu-item' :to='{ name: "MobileMarket", params: { stockCode: "AEX"}}' @click='closeMenu'>荷兰AEX综合指数</router-link>
    </div>

    <div class='menu-item expand-item' :class='{ "is-expand": expandMenu === "aus" }' @click='toggleExpandMenu("aus")'>澳洲</div>

    <div :ref='menus.aus' class='expand-menu' :class='{ "is-collapsed": isCollapsed }' :style="expandMenu === 'aus' ? 'height:'+menuHeight[3]+'px'  : ''">
      <router-link class='submenu-item' :to='{ name: "MobileMarket", params: { stockCode: "ASX200"}}' @click='closeMenu'>澳大利亚标准普尔200指数</router-link>
      <router-link class='submenu-item' :to='{ name: "MobileMarket", params: { stockCode: "NZX50"}}' @click='closeMenu'>新西兰NZSE 50指数</router-link>
      <router-link class='submenu-item' :to='{ name: "MobileMarket", params: { stockCode: "XAO"}}' @click='closeMenu'>澳交所普通股指数</router-link>
    </div>

    <div class='menu-item expand-item' :class='{ "is-expand": expandMenu === "battle" }' @click='toggleExpandMenu("battle")'>历史数据</div>

    <div :ref='menus.battle' class='expand-menu gap-0' :class='{ "is-collapsed": isCollapsed }' :style="expandMenu === 'battle' ? 'height:'+menuHeight[4]+'px'  : ''">
      <div class='menu-item expand-item expand-subitem' :class='{ "is-expand": expandSubMenu === "asia" }' @click='toggleExpandSubMenu("asia")'>亚洲指数</div>
      <div :ref='submenus.asia' class='expand-submenu' :class='{ "is-collapsed": isSubCollapsed }' :style="expandSubMenu === 'asia' ? 'height:'+subMenuHeight[0]+'px'  : ''">
        <router-link class='submenu-item' :to='{ name: "MobileBattle", params: { comparison: "KS11-vs-000001.SS"}}' @click='closeMenu'>韩国综合指数 VS 上海综合指数</router-link>
        <router-link class='submenu-item' :to='{ name: "MobileBattle", params: { comparison: "KS11-vs-N225"}}' @click='closeMenu'>韩国综合指数 VS 日经225指数</router-link>
        <router-link class='submenu-item' :to='{ name: "MobileBattle", params: { comparison: "HSI-vs-KS11"}}' @click='closeMenu'>香港恒生指数 VS 韩国综合指数</router-link>
        <router-link class='submenu-item' :to='{ name: "MobileBattle", params: { comparison: "N225-vs-000001.SS"}}' @click='closeMenu'>日经225指数 VS 上海综合指数</router-link>
        <router-link class='submenu-item' :to='{ name: "MobileBattle", params: { comparison: "000001.SS-vs-HSI"}}' @click='closeMenu'>上海综合指数 VS 香港恒生指数</router-link>
        <router-link class='submenu-item' :to='{ name: "MobileBattle", params: { comparison: "N225-vs-HSI"}}' @click='closeMenu'>日经225指数 VS 香港恒生指数</router-link>
      </div>

      <div class='menu-item expand-item expand-subitem' :class='{ "is-expand": expandSubMenu === "euro" }' @click='toggleExpandSubMenu("euro")'>欧洲指数</div>
      <div :ref='submenus.euro' class='expand-submenu' :class='{ "is-collapsed": isSubCollapsed }' :style="expandSubMenu === 'euro' ? 'height:'+subMenuHeight[1]+'px'  : ''">
        <router-link class='submenu-item' :to='{ name: "MobileBattle", params: { comparison: "FTSE-vs-GDAXI"}}' @click='closeMenu'>英国富时100指数 VS 德国DAX30指数</router-link>
        <router-link class='submenu-item' :to='{ name: "MobileBattle", params: { comparison: "CAC-vs-AEX"}}' @click='closeMenu'>法国CAC40指数 VS 荷兰AEX指数</router-link>
        <router-link class='submenu-item' :to='{ name: "MobileBattle", params: { comparison: "FTSE-vs-CAC"}}' @click='closeMenu'>英国富时100指数 VS 法国CAC40指数</router-link>
        <router-link class='submenu-item' :to='{ name: "MobileBattle", params: { comparison: "CAC-vs-GDAXI"}}' @click='closeMenu'>法国CAC40指数 VS 德国DAX30指数</router-link>
        <router-link class='submenu-item' :to='{ name: "MobileBattle", params: { comparison: "GDAXI-vs-AEX"}}' @click='closeMenu'>德国DAX30指数 VS 荷兰AEX指数</router-link>
        <router-link class='submenu-item' :to='{ name: "MobileBattle", params: { comparison: "AEX-vs-FTSE"}}' @click='closeMenu'>荷兰AEX指数 VS 英国富时100指数</router-link>
      </div>
    </div>

    </div>

  </div>
</div>
</template>

<style scoped>
.desktop .overlay-menu-wrapper{
  display:none;
}
.mobile .overlay-menu-wrapper{
  position:fixed;
  width:100vw;
  height:100%;
  background:#2e2e2e;
  z-index:7;
  color:#fff;
  font-size:15px;
  overflow-y:auto;
}
.mobile .overlay-menu{
  display: flex;
  flex-direction:column;
  text-align:center;
  margin-bottom:4rem;
}
.mobile .overlay-menu > *{
  border-bottom: 1px dashed #707070;
}
.mobile .expand-menu, .mobile .expand-submenu{
  display: flex;
  flex-direction:column;
  gap:10px;
  overflow: hidden;
  transition: .25s ease-in-out;
}
.mobile .expand-submenu{gap:0;}
.expand-menu.gap-0{gap:0;}
.expand-submenu, .expand-subitem.is-expand{
  border-bottom: 1px dashed #707070;
}
.mobile .expand-item{
  position:relative;
}
.mobile .expand-item:after{
  position:absolute;
  display:block;
  width:20px;
  height:20px;
  content:'';
  right:18px;
  top:18px;
  background: url(../../assets/down-arrow.svg) no-repeat center/cover;
  transition: all .2s ease-out;
}

.mobile .expand-subitem:after{
  width:18px;
  height:18px;
  right:20px;
  background: url(../../assets/plus.svg) no-repeat center/cover;
  opacity: .6;
}
.mobile .expand-item.is-expand:after{
  transform: rotate(180deg);
}
.mobile .expand-subitem.is-expand:after{
  transform: none;
  background: url(../../assets/minus.svg) no-repeat center/cover;
}
.mobile .menu-item{
  padding:15px 30px;
}
.mobile .submenu-item{
  padding:15px 30px;
}
.mobile .expand-submenu .submenu-item{
  color:#d6c29e;
}
.hamburger-container {
  position: absolute;
  top: 10px;
  right: 1rem;
  height: 32px;
  width: 24px;
  cursor: pointer;
  transition: opacity .25s ease;
  z-index:6;
}

.hamburger-container span {
  background: #000;
  border: none;
  height: 3px;
  width: 100%;
  position: absolute;
  top: 8px;
  left: 0;
  transition: all .15s ease;
  cursor: pointer;
}
.hamburger-container .middle {
  top: 14px;
}
.hamburger-container .bottom {
  top: 20px;
}
.hamburger-container.active {
  width: 20px;
}
.hamburger-container.active span {
  height: 3px;
  background: #fff;
}
.hamburger-container.active .top {
  transform: translateY(5px) translateX(0) rotate(45deg);
}
.hamburger-container.active .middle {
  opacity: 0;
}
.hamburger-container.active .bottom {
  transform: translateY(-7px) translateX(0) rotate(-45deg);
}

.is-collapsed{height:0;}



</style>