<script setup>
import {ref, defineEmits, onUnmounted, onMounted} from 'vue';
import axios from 'axios';
import { getTimezoneOffset } from 'date-fns-tz';
import LWChart from '@/components/LWChart';
import useColorClass from '@/hook/useColorClass';
import HomepageTable from '@/components/common/HomepageTable';
import HomepageAnalytic from '@/components/common/HomepageAnalytic';
import useConfig from '@/hook/useConfig';
import { stockArray } from '@/hook/useStockProperty';

const newslist = ref({})

axios.get('/api/News/GetNewsData?count=3').then(response => {
  newslist.value = response.data.data
})

const emit = defineEmits(['showModal'])
function triggerNews({title, contents}){
  emit('showModal',{title, content:contents})
}

const tableStatus = ref();
const asiaStockExchange = ['日经225指数','韩国综合指数','香港恒生指数','上海综合指数','富时新加坡海峡时报指数','越南胡志明股票指数']
const asiaIndexes = ref([]);

const euroStockExchange = ['英国富时100指数','德国DAX 30种股价指数','法CAC40指数','瑞士股票指数','西班牙IBEX指数','荷兰AEX综合指数']
const euroIndexes = ref([]);

const americaStockExchange = ['道琼斯','标准普尔500','纳斯达克','加拿大S&P/TSX综合指数','墨西哥BOLSA指数','巴西BOVESPA股票指数']
const americaIndexes = ref([]);

const pacificStockExchange = ['澳大利亚标准普尔200指数','新西兰NZSE 50指数','澳交所普通股指数']
const pacificIndexes = ref([]);

const mastheadArray = stockArray.reduce((result, item) =>  ({...result, [item.region]: [...(result[item.region] || []) , (item.region && item.sliderName) && item,], }) , {})
let activeChartTab = ref('asia')
let activeAnalyticTable = ref('asia')
const analyticTable = ref([]);

const chartType = ref('area');
const chartOptions = ref({
  handleScale:false,
  handleScroll:false,
  grid: {vertLines:false, horzLines:false}
});

const timeScaleOptions = ref({
  timeVisible:false,
  borderVisible: false,
});

const jpChart = ref();
const krChart = ref();
const hkChart = ref();
const shanghaiChart = ref();
const londonChart = ref();
const germanyChart = ref();
const franceChart = ref();
const netherlandChart = ref();
const chartArrayRef = ref([jpChart, krChart, hkChart, shanghaiChart, null, null, londonChart, germanyChart, franceChart, null, null, netherlandChart])
if (timeScaleOptions.value === false){
console.log(chartArrayRef, activeAnalyticTable)
}
const chartData = ref([])
const jpChartData = ref([])
const krChartData = ref([])
const hkChartData = ref([])
const shanghaiChartData = ref([])
const londonChartData = ref([])
const germanyChartData = ref([])
const franceChartData = ref([])
const netherlandChartData = ref([])
const chartDataArrayRef = ref([jpChartData, krChartData, hkChartData, shanghaiChartData, null, null, londonChartData, germanyChartData, franceChartData, null, null, netherlandChartData])


function timeToLocal(originalTime) {
  const d = new Date((originalTime ) * 1000);
  return Date.UTC(d.getFullYear(), d.getMonth(), d.getDate(), d.getHours(), d.getMinutes(), d.getSeconds(), d.getMilliseconds()) / 1000;
}

function populateChartData(chart){
  if (chart.length === 0) return []
  const unique = [...new Map(chart.map(item => [item['time'], item])).values()];
  const offset = getTimezoneOffset(stockArray.find(obj=>obj.id === chart[0].stockId).timeZone) * 0.001
  return unique.length > 0 ? unique.map( stock => ({ time: timeToLocal((new Date(stock.time).getTime()/1000) + offset - 28800), value: stock.price})) : []
}

const findChartDataById = (id) => chartData.value.find(chart => chart.stockId === id)

let pollingId = 0
!(async function f() {
  //setTimeout(()=>{

  axios.get('/api/StockResult/GetHot').then(response=>{
    chartData.value = response.data.data.hotStock
    jpChartData.value = populateChartData(response.data.data.lineGraph.filter(({stockId})=>stockId === 1))
    krChartData.value = populateChartData(response.data.data.lineGraph.filter(({stockId})=>stockId === 2))
    hkChartData.value = populateChartData(response.data.data.lineGraph.filter(({stockId})=>stockId === 3))
    shanghaiChartData.value = populateChartData(response.data.data.lineGraph.filter(({stockId})=>stockId === 4))
    londonChartData.value = populateChartData(response.data.data.lineGraph.filter(({stockId})=>stockId === 7))
    germanyChartData.value = populateChartData(response.data.data.lineGraph.filter(({stockId})=>stockId === 8))
    franceChartData.value = populateChartData(response.data.data.lineGraph.filter(({stockId})=>stockId === 9))
    netherlandChartData.value = populateChartData(response.data.data.lineGraph.filter(({stockId})=>stockId === 12))
  })

  axios.get('/api/StockResult/CurrentContinentsPriceList').then(response=>{
    tableStatus.value = response.data.isSuccess;
    asiaIndexes.value = response.data.data.find(({continentId}) => continentId === 1).indexItem.map((stock, index)=> {return { name: asiaStockExchange[index], ...stock}})
    euroIndexes.value = response.data.data.find(({continentId}) => continentId === 2).indexItem.map((stock, index)=> {return { name: euroStockExchange[index], ...stock}})
    americaIndexes.value = response.data.data.find(({continentId}) => continentId === 3).indexItem.map((stock, index)=> {return { name: americaStockExchange[index], ...stock}})
    pacificIndexes.value = response.data.data.find(({continentId}) => continentId === 4).indexItem.map((stock, index)=> {return { name: pacificStockExchange[index], ...stock}})
  })
  axios.get('/api/StockResult/CurrentIndexDetailPriceList').then(response=>{
    analyticTable.value = response.data.data
  })
  //}, 10000)
  pollingId = setTimeout (f, useConfig().interval)
}) ()
onMounted((()=>{
  document.title = "全球指数-全方位指数统计网站 | 首页";
}))
onUnmounted (() => clearTimeout (pollingId))
</script>
<template>
  <div class='masthead-news'>
    <div class='chart-tab'>
      <div @click='activeChartTab = "asia"' :class='{ active: activeChartTab ==="asia" }'>亚洲</div>
      <div @click='activeChartTab = "euro"' :class='{ active: activeChartTab ==="euro" }'>欧洲</div>
    </div>
    <div class='chart-wrapper'>

      <template v-if='chartData.length > 0'>

        <template v-for='chart in mastheadArray[activeChartTab]' :key='chart.code'>

            <div class='chart-item' @click='$router.push("/market/"+chart.code)'>
              <div class='chart' >
                <LWChart
                    :type='chartType'
                    :data='chartDataArrayRef[chart.id-1].value'
                    :autosize='true'
                    :chart-options='chartOptions'
                    :series-options='seriesOptions'
                    :time-scale-options='timeScaleOptions'
                    :result-option='findChartDataById(chart.id).increasePrice'
                />
                <div class='chart-overlay' v-if='chartDataArrayRef[chart.id-1].value.length === 0'>暂无资料</div>

              </div>

              <div class='leading-7'>{{chart.sliderName}}</div>
              <strong :class='"text-" + useColorClass(findChartDataById(chart.id).increasePrice)'>
                {{findChartDataById(chart.id).lastPrice}}
              </strong>
              <div class='leading-3 flex items-center justify-center mt-1 text-sm' :class='"text-" + useColorClass(findChartDataById(chart.id).increasePrice)'>
                <span :class='findChartDataById(chart.id).increasePrice > 0  ? "rising" : "dropping"'></span>
                {{findChartDataById(chart.id).increasePrice > 0 ? '+':''}}
                {{findChartDataById(chart.id).increasePrice}}
              </div>
            </div>


        </template>
      </template>
    </div>

  </div>

  <div class='domain-bg'>
    <div>
      备用域名1 <a href='https://gf225.com' target='_blank'>gf225.com</a>
    </div>
    <div>
      备用域名2 <a href='https://gf226.com'  target='_blank'>gf226.com</a>
    </div>
  </div>

  <marquee class='notification'>以下指数自动更新，所有报价单位均为当地货币，行情时间均为北京时间，除非特殊说明，均延迟至少30分钟。</marquee>

  <HomepageTable :title='"亚洲股市"' :tableData='asiaIndexes' placeholder='6' v-if='tableStatus !== false' />
  <HomepageTable :title='"欧洲股市"' :tableData='euroIndexes' placeholder='6' v-if='tableStatus !== false' />
  <HomepageTable :title='"美洲股市"' :tableData='americaIndexes' placeholder='6' v-if='tableStatus !== false' />
  <HomepageTable :title='"澳洲股市"' :tableData='pacificIndexes' placeholder='3' v-if='tableStatus !== false' />

  <div class='analytic-table-tab'>
    <div @click='activeAnalyticTable = "asia"' :class='{ active: activeAnalyticTable ==="asia" }'>亚洲</div>
    <div @click='activeAnalyticTable = "euro"' :class='{ active: activeAnalyticTable ==="euro" }'>欧洲</div>
  </div>

  <template v-if='analyticTable.length > 0'>

      <div v-if='activeAnalyticTable === "asia"'>
        <HomepageAnalytic :title='"上海综合指数"' :tableData='analyticTable[3]' />
        <HomepageAnalytic :title='"日经225指数"' :tableData='analyticTable[0]' />
        <HomepageAnalytic :title='"韩国综合指数"' :tableData='analyticTable[1]' />
        <HomepageAnalytic :title='"香港恒生指数"' :tableData='analyticTable[2]' />
      </div>

      <div v-if='activeAnalyticTable === "euro"'>
        <template v-if='analyticTable[4] || analyticTable[5] || analyticTable[6] || analyticTable[7]'>
          <template v-if='analyticTable[4]'><HomepageAnalytic :title='"英国富时100指数"' :tableData='analyticTable[4]' /></template>
          <template v-if='analyticTable[5]'><HomepageAnalytic :title='"德国DAX30指数"' :tableData='analyticTable[5]' /></template>
          <template v-if='analyticTable[6]'><HomepageAnalytic :title='"法国CAC40指数"' :tableData='analyticTable[6]' /></template>
          <template v-if='analyticTable[7]'><HomepageAnalytic :title='"荷兰AEX综合指数"' :tableData='analyticTable[7]' /></template>
        </template>

        <div v-else class='relative'>
          <HomepageAnalytic :title='"..."' :tableData='{priceFluctuation: 0, priceFluctuationRate: 0}' />
          <div class='blur-overlay'>暂无资料</div>
        </div>
      </div>
  </template>

  <div>
    <div class='news-wrapper' v-if='newslist.length > 0'>
      <template v-for='news in newslist' :key="'news-' + news">
        <div class='news-content cursor-pointer' @click='triggerNews(news)'>
          <h6 class='single-news-title flex-auto flex flex-col gap-2'>{{news.title}}</h6>
          <p class='ml-2 news-time'>
            {{new Intl.DateTimeFormat('zh-CN', { dateStyle: 'short'}).format(new Date(news.createdTime)) }}
          </p>
        </div>
      </template>
    </div>

    <div class='news-wrapper placeholder' v-else>
      <template v-for='news in 3' :key="'placeholder-news-' + news">
        <div class='news-content flex-auto flex flex-col gap-2'>
          <h6 class='box py-3 mt-1' :class='"w-"+ (Math.round( Math.random()* 2) + 2)+"/5"'></h6>
          <p class='ml-2 box py-2 px-12 w-8' style='margin-bottom:2px;'>
          </p>
        </div>
      </template>
    </div>

  </div>
</template>


<style scoped>
.section-title img{margin-top:-2px;}
.section-title{
  height:50px;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  border: 3px solid #EC6E6E;
  border-bottom: 2px solid #E2DAD0;
  display:flex;
  justify-content: center;
  align-items: center;
  font-size:18px;
  color: #BF4141;
  background: #fff;
}

.rising{
  margin-right:5px;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 0 4px 4px 4px;
  border-color: transparent transparent #e42918 transparent;
}
.dropping{
  margin-right:5px;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 4px 4px 0 4px;
  border-color: #028431 transparent transparent transparent;
}
.news-title img{margin-top:-2px;}
.news-title{
  color:#D67D4F;
  font-size:18px;
  height:55px;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  border: 3px solid #fff;
  background: #fff;
  width:50%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 1rem;
  padding-bottom: 1rem;
}
.news-wrapper{
  padding:10px 15px;
  background: #fff;
  display: flex;
  flex-direction: column;
  margin:10px;
  box-shadow: 0 2px 4px #00000033;
  border: 1px solid #CFCFCF;
  border-radius: 10px;
}
.news-content{
  margin-bottom:.25rem;
  padding-bottom:.8rem;
  border-bottom:1px solid #eee;
}
.news-content .single-news-title{
  font-size: 16px;
  font-weight:bold;
  line-height: 1.2rem;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  margin:.5rem 0;
  text-overflow: ellipsis;
  overflow:hidden;
  height:20px;
}
.news-time{
  color: #8A8A8A;
  font-size:12px;
}
.chart-wrapper{
  margin: .85rem 10px 1rem;
  display:flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
  transition: all .25s ease-out;
  gap:15px;
}
.chart-item{
  min-width: 90px;
  max-width:160px;
  box-shadow: 0 2px 4px #00000033;
  border: 1px solid #CFCFCF;
  border-radius: 10px;
  padding:16px 10px;
  width:50%;
  text-align:center;
}
.chart-item strong{
  font-size:20px;
}
.chart-info{width:100%;}
.chart-info-overview{
  display: flex;
  align-items: flex-end;
  justify-content: center;
  width:100%;
}
.chart {
  position: relative;
  height: 0;
  padding-bottom:30px;
  width: 100%;
  overflow:hidden;
}
.chart-tab{
  display:flex;
  margin:.85rem 18px .5rem;
}
.chart-tab > div{
  background: #f5efef;
  color: #929292;
  font-size:15px;
  border:2px solid transparent;
  border-bottom-width:0;
  padding:2px 25px;
  margin-top:8px;
}
.chart-tab .active{
  background: #fff;
  border: 2px solid #ec6e6e;
  border-bottom-color:transparent;
  border-radius: 6px 6px 0 0;
  color:#ec6e6e;
  padding:8px 25px;
  margin-top:0;
}
.chart:after{
  display:block;
  position:absolute;
  width:100%;
  z-index:2;
  bottom:1px;
  content:'..................................................';
  color:#bcbcbc;
  letter-spacing: 1px;
  font-size: 18px;
}
.chart > div{
  position:absolute;
  width:180px;
  height:50px;
  pointer-events: none;
}
.chart-overlay{
  opacity:0;
  pointer-events:none;
  transition:all .15s;
  width:100%!important;
  height:100%!important;
  position:absolute;
  top:0;left:0;
  background:rgba(0,0,0,.5);
  z-index:5;
  display:flex;
  align-items: center;
  justify-content: center;
  color:#fff;
  font-size:16px;
  backdrop-filter: blur(4px);
  border-radius:5px;
}
.total-price{
  font-size: 40px;
  font-weight: bold;
  flex:1;
  text-align:center;
}
.price-difference{
  display:flex;
  flex-wrap:nowrap;
  align-items: center;
  margin-left:auto;
  margin-right:0;
  flex-basis:100px;
  margin-bottom:8px;
  font-weight:bold;
}
.chart-analysis{
  display:flex;
  justify-content: space-around;
  text-align:right;
  margin: .5rem 0 1rem;
}
.chart-analysis .label{
  color: #707070;
  font-size:14px;
}


.notification{
  text-align: center;
  width:100%;
  padding:5px 10px;
  color:#707070;
  background: #F3EDE4;
  margin: .7rem auto;
}
.bottom-bg{
  background:url('../../assets/bottombg.png') no-repeat center top/cover;
}

.two-column > div{
  width:50%;
  margin-bottom: 1rem;
}
.row{margin-bottom:2rem;}
.domain-bg{
  font-size: 15px;
}
.analytic-table-tab{display:flex;color:#929292;font-size:20px;margin:1rem 10px .5rem;}
.analytic-table-tab > div{padding:10px 28px;cursor:pointer;border-bottom:2px solid #cecece;font-weight:bolder;}
.analytic-table-tab .active{color:#2E2E2E;border-bottom-color:#c79033;}
.blur-overlay{
  position:absolute;
  width: calc(100% - 20px);
  height: 100%;
  background:rgba(0,0,0,.5);
  z-index:2;
  display:flex;
  align-items: center;
  justify-content: center;
  color:#fff;
  font-size:16px;
  top:0px;left:10px;
  backdrop-filter: blur(2px);
  border-radius: 15px;
}
</style>
