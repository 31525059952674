<script setup>
import { ref } from 'vue';
import useMobile from '@/hook/useMobile';
const hoverMenu = ref('');
const flyoutMenu = ref('');
const clearHover = () => {
  hoverMenu.value = '';
  flyoutMenu.value = ''
}
const clearFlyout = (event) => {
  const isIncludeMenuClasslist = ['flyout-wrapper', 'submenu-item'].every(str => ![...event.target.classList].includes(str));
  if (isIncludeMenuClasslist) {
    flyoutMenu.value = ''
  }
};

</script>

<template>
  <div class='header' v-if='!useMobile()'>
    <div class='flex w-full gap-10 justify-around'>
      <div class='logo p-1'><router-link :to='"/"'>
        <img src='@/assets/img_logo.png' alt=''>
      </router-link></div>
      <div class='menu-items-group'>
        <router-link class='menu-item' :to='"/"'>首頁</router-link>
        <div
            @mouseover='hoverMenu = "asia"'
            @mouseout='clearHover'
            class='relative menu-item dropdown-menu-item cursor-pointer'
        >
          亚洲<span class='dropdown-arrow'></span>
          <div v-show='hoverMenu === "asia"' class='dropdown-menu-wrapper' style='width:13rem'>
            <div class='dropdown-menu'>
              <router-link class='submenu-item' :to='{ name: "market", params: { stockCode: "000001.SS"}}' @click='clearHover'>上海综合</router-link>
              <router-link class='submenu-item' :to='{ name: "market", params: { stockCode: "KS11"}}' @click='clearHover'>韩国综合</router-link>
              <router-link class='submenu-item' :to='{ name: "market", params: { stockCode: "HSI"}}' @click='clearHover'>香港恒生</router-link>
              <router-link class='submenu-item' :to='{ name: "market", params: { stockCode: "N225"}}' @click='clearHover'>日经指数</router-link>
              <router-link class='submenu-item' :to='{ name: "market", params: { stockCode: "STI"}}' @click='clearHover'>富时新加坡海峡时报指数</router-link>
              <router-link class='submenu-item' :to='{ name: "market", params: { stockCode: "VNI"}}' @click='clearHover'>越南胡志明股票指数</router-link>
            </div>
          </div>
        </div>
        <div
            @mouseover='hoverMenu = "america"'
            @mouseout='clearHover'
            class='relative menu-item dropdown-menu-item cursor-pointer'
        >
          美洲<span class='dropdown-arrow'></span>
          <div v-show='hoverMenu === "america"' class='dropdown-menu-wrapper' style='width:13rem'>
            <div class='dropdown-menu'>
              <router-link class='submenu-item' :to='{ name: "market", params: { stockCode: "DJI"}}' @click='clearHover'>道琼斯</router-link>
              <router-link class='submenu-item' :to='{ name: "market", params: { stockCode: "GSPC"}}' @click='clearHover'>标准普尔500</router-link>
              <router-link class='submenu-item' :to='{ name: "market", params: { stockCode: "IXIC"}}' @click='clearHover'>纳斯达克</router-link>
              <router-link class='submenu-item' :to='{ name: "market", params: { stockCode: "TSX"}}' @click='clearHover'>加拿大S&P/TSX综合指数</router-link>
              <router-link class='submenu-item' :to='{ name: "market", params: { stockCode: "MXX"}}' @click='clearHover'>墨西哥BOLSA指数</router-link>
              <router-link class='submenu-item' :to='{ name: "market", params: { stockCode: "BVSP"}}' @click='clearHover'>巴西BOVESPA股票指数</router-link>
            </div>
          </div>
        </div>

        <div
            @mouseover='hoverMenu = "euro"'
            @mouseout='clearHover'
            class='relative menu-item dropdown-menu-item cursor-pointer'
        >
          欧洲<span class='dropdown-arrow'></span>
          <div v-show='hoverMenu === "euro"' class='dropdown-menu-wrapper' style='width:13rem'>
            <div class='dropdown-menu'>
              <router-link class='submenu-item' :to='{ name: "market", params: { stockCode: "FTSE"}}' @click='clearHover'>英国富时100指数</router-link>
              <router-link class='submenu-item' :to='{ name: "market", params: { stockCode: "GDAXI"}}' @click='clearHover'>德国DAX 30种股价指数</router-link>
              <router-link class='submenu-item' :to='{ name: "market", params: { stockCode: "CAC"}}' @click='clearHover'>法CAC40指数</router-link>
              <router-link class='submenu-item' :to='{ name: "market", params: { stockCode: "SMI"}}' @click='clearHover'>瑞士股票指数</router-link>
              <router-link class='submenu-item' :to='{ name: "market", params: { stockCode: "IBEX"}}' @click='clearHover'>西班牙IBEX指数</router-link>
              <router-link class='submenu-item' :to='{ name: "market", params: { stockCode: "AEX"}}' @click='clearHover'>荷兰AEX综合指数</router-link>
            </div>
          </div>
        </div>

        <div
            @mouseover='hoverMenu = "aus"'
            @mouseout='clearHover'
            class='relative menu-item dropdown-menu-item cursor-pointer'
        >
          澳洲<span class='dropdown-arrow'></span>
          <div v-show='hoverMenu === "aus"' class='dropdown-menu-wrapper' style='width:13rem'>
            <div class='dropdown-menu'>
              <router-link class='submenu-item' :to='{ name: "market", params: { stockCode: "ASX200"}}' @click='clearHover'>澳大利亚标准普尔200指数</router-link>
              <router-link class='submenu-item' :to='{ name: "market", params: { stockCode: "NZX50"}}' @click='clearHover'>新西兰NZSE 50指数</router-link>
              <router-link class='submenu-item' :to='{ name: "market", params: { stockCode: "XAO"}}' @click='clearHover'>澳交所普通股指数</router-link>
            </div>
          </div>
        </div>

        <div
            @mouseover='hoverMenu = "battle"'
            @mouseout='clearHover'
            class='relative menu-item dropdown-menu-item cursor-pointer'
        >
          历史数据<span class='dropdown-arrow'></span>
          <div v-show='hoverMenu === "battle"' class='dropdown-menu-wrapper battle-dropdown-menu'>
            <div class='dropdown-menu hover-menu'>
              <div class='flyout-wrapper' :class='{ "is-hover": flyoutMenu === "asia" }' @mouseover='flyoutMenu = "asia"' @mouseout='clearFlyout'>
                亚洲指数
                <div class='flyout-menu' v-show='flyoutMenu === "asia"'>
                  <router-link class='submenu-item' :to='{ name: "MobileBattle", params: { comparison: "KS11-vs-000001.SS"}}' @click='clearHover'>韩国综合指数 VS 上海综合指数</router-link>
                  <router-link class='submenu-item' :to='{ name: "MobileBattle", params: { comparison: "KS11-vs-N225"}}' @click='clearHover'>韩国综合指数 VS 日经225指数</router-link>
                  <router-link class='submenu-item' :to='{ name: "MobileBattle", params: { comparison: "HSI-vs-KS11"}}' @click='clearHover'>香港恒生指数 VS 韩国综合指数</router-link>
                  <router-link class='submenu-item' :to='{ name: "MobileBattle", params: { comparison: "N225-vs-000001.SS"}}' @click='clearHover'>日经225指数 VS 上海综合指数</router-link>
                  <router-link class='submenu-item' :to='{ name: "MobileBattle", params: { comparison: "000001.SS-vs-HSI"}}' @click='clearHover'>上海综合指数 VS 香港恒生指数</router-link>
                  <router-link class='submenu-item' :to='{ name: "MobileBattle", params: { comparison: "N225-vs-HSI"}}' @click='clearHover'>日经225指数 VS 香港恒生指数</router-link>
                </div>
              </div>
              <div class='flyout-wrapper' :class='{ "is-hover": flyoutMenu === "euro" }' @mouseover='flyoutMenu = "euro"' @mouseout='clearFlyout'>
                欧洲指数
                <div class='flyout-menu' v-show='flyoutMenu === "euro"'>
                  <router-link class='submenu-item' :to='{ name: "MobileBattle", params: { comparison: "FTSE-vs-GDAXI"}}' @click='clearHover'>英国富时100指数 VS 德国DAX30指数</router-link>
                  <router-link class='submenu-item' :to='{ name: "MobileBattle", params: { comparison: "CAC-vs-AEX"}}' @click='clearHover'>法国CAC40指数 VS 荷兰AEX指数</router-link>
                  <router-link class='submenu-item' :to='{ name: "MobileBattle", params: { comparison: "FTSE-vs-CAC"}}' @click='clearHover'>英国富时100指数 VS 法国CAC40指数</router-link>
                  <router-link class='submenu-item' :to='{ name: "MobileBattle", params: { comparison: "CAC-vs-GDAXI"}}' @click='clearHover'>法国CAC40指数 VS 德国DAX30指数</router-link>
                  <router-link class='submenu-item' :to='{ name: "MobileBattle", params: { comparison: "GDAXI-vs-AEX"}}' @click='clearHover'>德国DAX30指数 VS 荷兰AEX指数</router-link>
                  <router-link class='submenu-item' :to='{ name: "MobileBattle", params: { comparison: "AEX-vs-FTSE"}}' @click='clearHover'>荷兰AEX指数 VS 英国富时100指数</router-link>
                </div>
              </div>
            </div>
          </div>
        </div>

      </div>
    </div>
  </div>

</template>


<style scoped>

.logo{display:flex;justify-content:center;align-items: center;padding:0 10px;width:368px;}
.menu-items-group{
  display:flex;
  gap:10px;
  flex:1;
  justify-content: space-between;
  margin-right:0;
  margin-left:auto;
  align-items:center;
}
.menu-item{
  width:10rem;
  padding:.75rem 2rem;
  display:flex;
  flex-wrap:nowrap;
  justify-content:center;
  border-radius:10px;
  color:#fff;
  font-size:15px;
  background: #c79033;
  z-index:3;
}
.submenu-item{margin:.1rem auto;width:100%;text-align:center;padding:.5rem .85rem;}

.dropdown-menu-wrapper{
  width:10rem;
  position:absolute;
  z-index:2;
  transform: translateY(1rem);
}
.dropdown-menu{
  margin-top:1.65rem;
  padding:.75rem 0;
  display: flex;
  flex-direction: column;
  border-radius:10px;
  color:#fff;
  background: #c79033;
}
.dropdown-menu-item{
  position:relative;
}
.dropdown-arrow{
  margin-top:-2px;
  margin-left:6px;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 4px 4px 0 4px;
  border-color: #ffffff transparent transparent transparent;
  align-self: center;
}
.battle-dropdown-menu{
  width:10rem;
  transform: translate(0, 1rem);
}
.hover-menu{
  padding:0;
  text-align: center;
  background: #c79033;
}

.hover-menu > div:last-of-type{margin-bottom:0}
.flyout-wrapper{position:relative;padding:15px 10px 10px;}
.flyout-wrapper:last-of-type{padding-bottom:15px;}
.flyout-wrapper:before{
  position:absolute;
  content:'';
  width:15px;
  height:15px;
  left:15px;
  top:20px;
  background: url(../../assets/down-arrow.svg) no-repeat center/cover;
  transition: all .2s ease-out;
  transform: rotate(90deg);
}
.flyout-wrapper.is-hover:before{transform: rotate(270deg);}
.flyout-menu{
  position:absolute;
  padding: 0.75rem 0;
  top:0;
  display: flex;
  flex-direction: column;
  color: #fff;
  background: #a47320;
  box-shadow: 0 3px 4px #0000004D;
  border-radius: 6px;
  width: 18rem;
  z-index:1;
  transform: translate(-18.25rem, -.1rem );
}
@media(min-width: 2020px){
  .flyout-menu{
    transform: translate(8rem, -.1rem );
  }
  .flyout-wrapper:before{
    left:auto;
    right:20px;
    transform: rotate(270deg);
  }
  .flyout-wrapper.is-hover:before{transform: rotate(90deg);}
}
</style>
