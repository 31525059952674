<script setup>
import {onUnmounted, onMounted, ref, watch} from 'vue';
import useColorClass from '@/hook/useColorClass';
import { useRoute } from 'vue-router';
import axios from 'axios';
import useConfig from '@/hook/useConfig';
import useStockProperty from '@/hook/useStockProperty';
import { format, add } from 'date-fns';

const route = useRoute();
const { stockCode } = route.params;
const currentStock = ref(useStockProperty(stockCode))
const totalItems = ref(0);
const currentPage = ref(1);
const indexData = ref({})
const historyData = ref([])
const elapseTimer = ref({
  day:0,
  hours:0,
  minutes:0,
  seconds:0,
})

let pollingId = 0;
let elapseId = 0;
let increment = 0;
function polling(){
  axios.get(`/api/StockResult/History?stockId=${currentStock.value.id}&pageIndex=${currentPage.value}&pageSize=${useConfig().pageSize}`).then(response=>{
    indexData.value = response.data.data.current
    historyData.value = response.data.data.history
    totalItems.value = response.data.data.recordCount
    if (increment === 0){
      elapseCloseTime();
    }
    increment++;
  })
  pollingId = setTimeout (polling, useConfig().interval)
}
const onClickHandler = (page) => {
  currentPage.value = page

  axios.get(`/api/StockResult/History?stockId=${currentStock.value.id}&pageIndex=${currentPage.value}&pageSize=${useConfig().pageSize}`).then(response=>{
    indexData.value = response.data.data.current
    historyData.value = response.data.data.history
    totalItems.value = response.data.data.recordCount
  })
};


watch(route, ({params}) => {
  currentStock.value = useStockProperty(params.stockCode);
  initialState();
});


function initialState(){
  increment = 0;
  indexData.value = {}
  historyData.value = []
  elapseTimer.value = {
    day:0,
    hours:0,
    minutes:0,
    seconds:0,
  }
  totalItems.value = 0;
  currentPage.value = 1;
  clearTimeout (pollingId);
  clearTimeout (elapseId)
  polling();
}

function elapseCloseTime(){
  let now = new Date();

  if (indexData.value.isOpening) {
    if (new Date(indexData.value.openDateTime.split('T')[0].replace(/-/g, "/")).getDate() === new Date().getDate()) {
      let closingDateTime = new Date( format(now, 'yyyy/MM/dd') + ' ' + indexData.value.closeTime)
      if (now.getTime() < closingDateTime.getTime()) {
        let totalSeconds = (closingDateTime.getTime() - now.getTime()) / 1000
        elapseTimer.value = {
          day: Math.floor(totalSeconds / 86400),
          hours: Math.floor(totalSeconds / 3600),
          minutes: Math.floor((totalSeconds % 3600) / 60),
          seconds: Math.floor((totalSeconds % 3600) % 60)
        }
        elapseId = setTimeout(elapseCloseTime, 1000)
      }else{
        let closingDateTime = new Date( format(add(now,{ days:1 }), 'yyyy/MM/dd') + ' ' + indexData.value.closeTime)
        let totalSeconds = (closingDateTime.getTime() - now.getTime()) / 1000
        elapseTimer.value = {
          day: Math.floor(totalSeconds / 86400),
          hours: Math.floor(totalSeconds / 3600),
          minutes: Math.floor((totalSeconds % 3600) / 60),
          seconds: Math.floor((totalSeconds % 3600) % 60)
        }
        elapseId = setTimeout(elapseCloseTime, 1000)
      }
    }
  }
}

const wrapLastDigit = (digit)=> {
  const string = digit.toString()
  if (/\./g.test(string)){
    const formattedString = digit.toFixed(2).toString()
    return [...formattedString].reverse().map((element, index) => (index === 3) ? `<strong class='text-blue'>${element}</strong>`: element).reverse().join('')
  }else{
    return [...string].reverse().map((element, index) => (index === 0) ? `<strong class='text-blue'>${element}</strong>`: element).reverse().join('')
  }
}

onMounted (()=>{
  polling()
  document.title = "全球指数-全方位指数统计网站 | "+currentStock.value?.name;
})
onUnmounted (() => {clearTimeout (pollingId);clearTimeout (elapseId)})

</script>
<template>
<div class='w-full body-wrapper'>
  <div class='overview my-14'>
    <div class='row flex items-center' v-if='indexData.openDateTime'>
      <h1>{{currentStock.name}}</h1>
      <div class='datetime'>{{indexData.openDateTime?.replace('T', ' ')}}</div>
    </div>
    <div v-else class='row flex items-center placeholder'>
      <h1>{{currentStock.name}}</h1>
      <div class='box py-4 w-52'></div>
    </div>
    <div class='content-wrapper'>
      <div class='flex items-center my-8' style='height:75px'>
        <div class='countdown-wrapper w-1/2'>
          距离收盘
          <div class='countdown-timer' v-if='indexData.closeTime'>
            <div>
              {{elapseTimer.day}}
              <div class='unit'>天</div>
            </div>

            <div>
              {{elapseTimer.hours}}
              <div class='unit'>时</div>
            </div>

            <div>
              {{elapseTimer.minutes}}
              <div class='unit'>分</div>
            </div>

            <div>
              {{elapseTimer.seconds}}
              <div class='unit'>秒</div>
            </div>
          </div>
          <div class='countdown-timer' v-else>
            <div>&nbsp;
              <div class='unit'>天</div>
            </div>
            <div>&nbsp;
              <div class='unit'>时</div>
            </div>
            <div>
              &nbsp;
              <div class='unit'>分</div>
            </div>
            <div>
              &nbsp;
              <div class='unit'>秒</div>
            </div>
          </div>
        </div>
        <div class='flex w-1/2 items-center' v-if='indexData.openPrice'>
          <div class='price flex align-center' :class='"text-"+useColorClass(indexData.increasePrice)'>{{indexData.lastPrice}}</div>
          <template v-if='indexData.increasePrice !== 0'>
            <div :class='(indexData.increasePrice > 0 ? "rising" : "dropping")+"-arrow"'><span></span></div>
          </template>
          <template v-else>
            <div class='even-symbol'><span></span></div>
          </template>
          <div class='flex difference text-left items-center ml-6 gap-6' :class='"text-"+useColorClass(indexData.increasePrice)'>
            <div>{{indexData.increasePrice > 0 ? '+' : ''}}{{indexData.increasePrice.toFixed(2)}}</div>
            <div>{{indexData.increasePrice > 0 ? '+' : ''}}{{indexData.increaseRate}}%</div>
          </div>
        </div>
        <div class='flex w-1/2 placeholder' v-else>
          <div class='price flex align-center box w-1/3 py-7'></div>
          <div><span>&nbsp;</span></div>
          <div class='flex difference text-left items-center ml-6 gap-6 '>
            <div class='box w-24 py-4'></div>
            <div class='box w-16 py-4'></div>
          </div>
        </div>
      </div>

      <table class='overview-table'>
        <thead>
          <tr>
            <th>今开</th>
            <th>昨收</th>
            <th>最高</th>
            <th>最低</th>
            <th>振幅</th>
          </tr>
        </thead>
          <tbody>
            <tr>
              <td :class='"text-" + useColorClass(indexData.increasePrice)'>
                {{indexData.openPrice}}
              </td>
              <td>
                {{indexData.yesterdayPrice}}
              </td>
              <td :class='"text-" + useColorClass(indexData.increasePrice)'>
                {{indexData.highPrice}}
              </td>
              <td>
                {{indexData.lowPrice}}
              </td>
              <td>
                <span v-if='indexData.amplitudeRate || indexData.amplitudeRate === 0'>{{indexData.amplitudeRate.toFixed(2)}}%</span>
                <span v-else>&nbsp;</span>
              </td>
            </tr>
          </tbody>
      </table>
    </div>
  </div>

  <div class='history-tab'>
    <h1 class='title-section'><img src='@/assets/history-white.svg' alt=''> 历史数据 </h1>
    <vue-awesome-paginate
        v-if='totalItems > useConfig().pageSize'
        :total-items='totalItems'
        :items-per-page='useConfig().pageSize'
        v-model='currentPage'
        backButtonClass='left-arrow'
        nextButtonClass='right-arrow'
        prev-button-content='&nbsp;'
        next-button-content='&nbsp;'
        :on-click='onClickHandler'
    />
    <table class='history-table bg-white'>
      <thead>
      <tr>
        <th>时间</th>
        <th>关盘指数</th>
        <th>单双</th>
        <th>大小</th>
      </tr>
      </thead>
      <tbody v-if='historyData?.length > 0'>
      <tr v-for='(history, index) in historyData' :key='"history" + index'>
        <td>{{history.date.replace(/\//g,'-')}}</td>
        <td>

          <div class='flex justify-center items-center relative'>
            <span v-html='wrapLastDigit(history.lastPrice)'></span>
            <div class='price-difference' :class='"text-" + useColorClass(history.increasePrice)'>
              <span :class='history.increasePrice > 0  ? "rising" : history.increasePrice === 0 ? "even" : "dropping"'></span>
              {{history.increasePrice > 0  ? '+' : ''}}{{history.increasePrice}}
            </div>
          </div>

        </td>
        <td>{{history.oddEven}}</td>
        <td>{{history.bigSmall}}</td>
      </tr>
      </tbody>

      <tbody v-else>
      <tr v-for='item in new Array(6).fill({})' :key='item.index'>
        <td>
          <div class='flex justify-center items-center placeholder'>
            <div class='py-3 box w-1/2'></div>
          </div>
        </td>
        <td>
          <div class='flex justify-center items-center relative placeholder'>
            <div class='py-3 box w-40'></div>
            <div class='py-3 box w-20 absolute right-8'></div>
          </div>
        </td>
        <td>
          <div class='flex justify-center items-center relative placeholder'>
            <div class='py-3 box w-20'></div>
          </div>
        </td>
        <td>
          <div class='flex justify-center items-center relative placeholder'>
            <div class='py-3 box w-20'></div>
          </div>
        </td>
      </tr>
      </tbody>
    </table>
  </div>

</div>
</template>


<style scoped>
.body-wrapper{
  background-image:url('../../../assets/bottombg.png');
  background-size: 100%;
  background-position-y:100px;
}
.overview{
  background: #fff;
  box-shadow: 0 3px 8px #00000033;
  border: 3px solid #E1CAAA;
  border-radius: 10px;
  padding: 20px;
}
.overview h1{
  font-size:32px;
  color: #1F3471;
  font-weight:bold;
  width: 50%;
}
.overview-table{
  background: #FBF1E3;
  width:100%;
  text-align:center;
  color:#707070;
  font-weight:bold;
}
.overview-table th{font-size:18px;  padding: 10px;
}
.overview-table td, .overview-table th{
  border: 2px solid #E1CAAA;
  width:1%;
  font-weight:bold;
}
.overview-table td{font-size:16px;  padding: 12px 10px;}

.datetime{
  display:block;
  flex:1;
  color: #545454;
  font-size:20px;
  font-weight:bold;
  width: 50%;
}
.countdown-wrapper{
  display:flex;
  align-items: center;
  font-weight: bold;
  color:#545454;
  font-size:20px;
}
.countdown-timer{
  color:#fff;
  font-size: 30px;
  font-weight:bold;
  display:flex;
  margin-left:20px;
}
.countdown-timer > div{
  background: #1F3471;
  border-radius: 8px;
  text-align:center;
  width:70px;
  height:70px;
  display:flex;
  flex-direction: column;
  line-height:26px;
  padding-top:14px;
  margin-left:10px;
}
.countdown-timer .unit{
  font-size: 16px;
  color: #fff;
  opacity: .6;
  margin-bottom:4px;
  margin-top:auto;
}
.overview .price{font-size:54px;font-weight:bold;}
.difference{font-size:20px;}

.rising-arrow, .dropping-arrow, .even-symbol{
  position:relative;
  margin-left:2rem;
}
.rising-arrow:before{
  content:'';
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 0 10px 10px 10px;
  border-color: transparent transparent #e42918 transparent;
  position:absolute;
  top:50%;
  transform: translate(-8px, -10px);
}
.rising-arrow span{
  width:3px;
  height:10px;
  background: #e42918;
  position:absolute;
  top:50%;
  transform: translate(0, -2px);
}
.dropping-arrow:before{
  content:'';
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 10px 10px 0 10px;
  border-color: #028431 transparent transparent transparent;
  position:absolute;
  top:50%;
  transform: translate(-12px, 0);
}
.dropping-arrow span{
  width:3px;
  height:10px;
  background: #028431;
  position:absolute;
  top:50%;
  transform: translate(-3px, -10px);
}
.even-symbol{
  width:20px;height:2px;background:#545454;
  transform: translate(calc(-50% + 14px), 0);
  margin-left:1.35rem;
}
.history-tab{
  display:flex;
  flex-wrap:wrap;
}
.title-section {
  color: #A76E2D;
  background: #EFD4AB;
  border-radius: 10px 10px 0 0;
  font-size: 20px;
  font-weight: bold;
  padding: 15px 25px;
  display: inline-flex;
  justify-content: space-between;
  align-items: center;
  gap:10px;
}
.history-table{
  width:100%;
  border-collapse: collapse;
}
.history-table th, .history-table td{
  width:1%;
  padding:25px 0;
  text-align: center;
  border: 2px solid #EFD4AB;
}
.history-table td{
  color:#545454;
  font-size:14px;
}
.history-table tbody tr:nth-child(odd){
  background: #F8F5EA;
}
.history-table th{
  font-size: 17px;
  color: #A76E2D;
}
.price-difference {
  font-size:14px;
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  position:absolute;
  text-align:left;
  right:10px;
  width: 100px;
}
.rising{
  margin-right:5px;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 0 6px 6px 6px;
  border-color: transparent transparent #e42918 transparent;
}
.dropping{
  margin-right:6px;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 6px 6px 0 6px;
  border-color: #028431 transparent transparent transparent;
}

.even{
  margin-right:25px;
  position:relative;
}
.even:after{
  content:'';
  width:10px;
  height:1px;
  background:#545454;
  position:absolute;
  top:-1px;
  left:0;
}
.even-symbol{
  width:20px;height:2px;background:#545454;
  transform: translate(calc(-50% + 10px), 0);
}

</style>
