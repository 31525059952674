import { createRouter, createWebHashHistory } from 'vue-router';
import Homepage from './pages/desktop/Homepage';
import {default as MobileHomepage} from './pages/mobile/Homepage';
import {default as MobileMarketIndex} from './pages/mobile/market/MarketIndex';
import {default as MobileBattleIndex} from './pages/mobile/battle/BattleIndex';
import About from './pages/common/About.vue';
import Disclaimer from './pages/common/Disclaimer.vue';
import MarketIndex from './pages/desktop/market/MarketIndex.vue';
import BattleIndex from './pages/desktop/battle/BattleIndex.vue';

const routes = [
  {
    path: '/mobile',
    component: MobileHomepage,
    name: 'MobileHomepage' },
    {
      path: '/mobile/market/:stockCode',
      name: 'MobileMarket',
      component: MobileMarketIndex,
    },
    {
      path: '/mobile/battle/:comparison',
      name: 'MobileBattle',
      component: MobileBattleIndex,
    },
    {
      path: '/mobile/about',
      name: 'MobileAbout',
      component: About
    },
    {
      path: '/mobile/disclaimer',
      name: 'MobileDisclaimer',
      component: Disclaimer
    },
  {
    path: '/',
    name: 'Homepage',
    component: Homepage
  },
  {
    path: '/about',
    name: 'about',
    component: About
  },
  {
    path: '/disclaimer',
    name: 'disclaimer',
    component: Disclaimer
  },
  {
    path: '/market/:stockCode',
    name: 'market',
    component: MarketIndex,
  },
  {
    path: '/battle/:comparison',
    name: 'battle',
    component: BattleIndex,
  },
  {
    path: "/:catchAll(.*)",
    name: "Not Found",
    component: Homepage,
  },
]


const router = createRouter({
  history: createWebHashHistory(),
  scrollBehavior() {
    // always scroll to top
    return { top: 0 }
  },
  routes
})

export default router
